import { homeData } from "../constants/screensData";

export const styles = {
  container: {
    minHeight: "100vh",
    width: "100%",
    background: `url(${homeData.backgroundImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    
  },
  logo: {
    width: "10rem",
    height: "5.132rem",
  },
  userIcon: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },
  linkBox:{
    background: "linear-gradient(105deg, #461E96 0%, #4400D6 97.51%)",
    textAlign: "center",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "18vh",
    fontFamily: "Abgilroy Medium",
    fontSize: "20px",
    borderRadius:"5px",
    cursor:"pointer",
    
  }
  
};
