import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import DashboardLayout from "../../components/dashboardLayout/DashboardLayout";
import RootGuard from "../../components/routes/routeGuard";
import ViewScreen from "./viewScreen/ViewScreen";
import GraphScreen from "./graphScreen/GraphScreen";
import { TourProvider, useTour } from "@reactour/tour"
import { useState, useEffect, Dispatch, SetStateAction } from 'react';


const steps = [
  {
    selector: '.first-step',
    content: 'Select a desired view. A view represents a subset of the data from the entire knowledge graph',
    
  },
  {
    selector: '.second-step',
    content: 'Search for predefined query, nodes, relationships, favorites and entities on the rendered graph',
  },
  { 
    selector: '.third-step',
    content: 'Go back to the views page',
  },
  {
    selector: '.fourth-step',
    content: 'Write your own cypher query here',
  },
  {
    selector: '.fifth-step',
    content: 'Filter from the given set of nodes and relationships from your current graph',
  },
  {
    selector: '.sixth-step',
    content: 'Find and manage your predefined queries here', 
  },
  {
    selector: '.seven-step',
    content: 'Find and manage your favorite queries here', 
  },
  {
    selector: '.eight-step',
    content: 'Build your own cypher query by selecting the nodes and relationships',
  },
  {
    selector: '.nine-step',
    content: 'Customize the maximun nodes to render in graph',
  },
  {
    selector: '.ten-step',
    content: 'Shows the ontology of the selected view',
  }
]
      

const AppScreen = () => {
  const redirect = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(0);
  const { setIsOpen } = useTour()
  
  const setCurrentStep = (step) => {
    switch (step) {
      case 0:
        redirect("/views");
        break;
      case 1:
        redirect(`/views/graph/{id}`);
        break;
      case 2:
        redirect(`/views/graph/{id}`);
        break;
      default:
        // redirect("/views/graph/0")
        break;
    }
    setStep(step);
  };

  return (
    <TourProvider
      steps={steps}
      currentStep={step}
      setCurrentStep={setCurrentStep}
      position={"right"}
      startAt={0}
    >
      <DashboardLayout setStep={setStep}>
        <RootGuard>
          <Routes>
            <Route path="/" element={<ViewScreen />} />
            <Route path={routes.graph} element={<GraphScreen />}></Route>
          </Routes>
        </RootGuard>
      </DashboardLayout>
    </TourProvider>

  );
};  
export default AppScreen;
  
