/**
 * DashboardLayout Component
 *
 * The `DashboardLayout` component is a layout wrapper used for rendering the main content
 * of the dashboard pages. It includes a header component and displays the main content of the
 * dashboard page along with breadcrumb navigation when the user navigates to subpages.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The child components to be rendered inside the layout.
 *
 * @returns {React.ReactNode} The `DashboardLayout` component.
 */

import Header from "../header";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { path } from "../../constants/routes";
import { menuItems } from "../../constants/menuItems";
import { DashboardLayoutProps } from "../../@types/components";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { axiosinstance } from "../../api/interceptor";
import Sidebar from "../sidebar/Sidebar";
import React from "react";
import sidebarReducer, { setFavoritesOpen, setFilterOpen, setIsFromAddPredefinedQuery, setOntologyOpen, setPredefineQueryOpen, setQueryBuilderOpen, setRunQueryOpen, setSettingsOpen } from "../../redux/reducers/sidebarReducer";
import { useAppDispatch } from "../../redux/hooks/hook";
import { useTour } from "@reactour/tour"
import MenuDrawer from "../menuDrawer/menuDrawer";

const DashboardLayout = ({ children, setStep }: DashboardLayoutProps) => {
  const location = useLocation();
  const redirect = useNavigate();
  const dispatch = useAppDispatch();
  const { setIsOpen, isOpen } = useTour();

 
  /**
   * Check if the current location matches the dashboard's main path.
   *
   * @returns {boolean} True if the current location matches the dashboard's main path, false otherwise.
   */
  const isCurrentPath = (): boolean => {
    return location.pathname === path || location.pathname === `${path}/`;
  };
  /**
   * Get the breadcrumb text for the current page based on the location pathname.
   *
   * @returns {string} The breadcrumb text for the current page, or an empty string if not found.
   */
  const breadcrumbText = (): string => {
    return (
      menuItems.find((item) => item.href === location.pathname)?.title || ""
    );
  };

  const [isTabActive, setIsTabActive] = useState(true);
  const [activeTab, setActiveTab] = useState('');

  const handleMenuPopover = (item: string): void => {
    if (item === "Filters") {
      // write a dispatch to update the filter in store
      dispatch(setFilterOpen())
      dispatch(setFavoritesOpen(false))
      dispatch(setSettingsOpen(false))
      dispatch(setPredefineQueryOpen(false))
      setIsTabActive(true)
      setActiveTab('Filters')
    }
    if (item === "Run Query") {
      dispatch(setRunQueryOpen())
      dispatch(setIsFromAddPredefinedQuery(false))
    }
    if (item === "Favorites") {
      dispatch(setFavoritesOpen())
      dispatch(setFilterOpen(false))
      dispatch(setSettingsOpen(false))
      dispatch(setPredefineQueryOpen(false))
      setIsTabActive(true)
      setActiveTab('Favorites')


    }
    if (item === "Settings") {
      dispatch(setSettingsOpen())
      dispatch(setFilterOpen(false))
      dispatch(setFavoritesOpen(false))
      dispatch(setPredefineQueryOpen(false))
      setIsTabActive(true)
      setActiveTab('Settings')

    }
    if(item === "Predefined Query"){
      dispatch(setPredefineQueryOpen())
      dispatch(setSettingsOpen(false))
      dispatch(setFilterOpen(false))
      dispatch(setFavoritesOpen(false))
      setIsTabActive(true)
      setActiveTab('Predefined Query')
    }
    if (item === "Help") {
      setStep(0); 
      redirect("/views");
      // handleTour();
      setIsOpen(true); 
      
    }
    if(item === "Query Builder"){
      dispatch(setQueryBuilderOpen())
    }
    if(item === "Ontology"){
      dispatch(setOntologyOpen())
    }

    else {
      setIsTabActive(false); // Close tab if any other item is clicked
      setActiveTab('');
  }

  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Box data-testid="dashboard-layout">
        <Header />
        <Box sx={{ paddingTop: "4rem", display: "flex", flexDirection: "row", width: "100%", gap: 10, backgroundColor: "#F5F5F5", height: "100vh", overflowY: "scroll" }}>
          <Sidebar open={true} handleMenuPopover={handleMenuPopover} handleDrawerClose={() => { }}  isTabActive={isTabActive} activeTab={activeTab}/>
          <Box width="100%" sx={{ paddingX: "1rem" }}>
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
