import { Modal, Box, Typography, Button, IconButton } from "@mui/material"
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hook";
import { setFavoritesOpen, setRunQueryOpen } from "../../redux/reducers/sidebarReducer";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LoadingButton } from "@mui/lab";
import { createFavorite, runCipherQuery } from "../../api/api";
import { Toaster } from "react-hot-toast"
import { CustomToast } from "../../helpers/customToast";
import FavoriteQueryModal from "./FavoriteQueryModal";
import { AddPredefinedQueryModal } from "../predefinedQuery/PredefinedQuery";



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "61rem",
    height: "auto",
    bgcolor: '#ffffff',
    borderRadius: "0.625rem",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
    p: "1.3rem",
};


const RunQueryModal = ({ onSubmitRun, queryRunning, selectedFavQuery, isFavoriteDisabled, onChangeQuery, setSelectedFavQuery }) => {
    const dispatch = useAppDispatch()
    const [isQueryModalOpen, setQueryModalOpen] = React.useState(false);
    const [cipherQuery, setCipherQuery] = useState<string>("");
    const [description, setDescription] = useState<string>("")
    const [favoriteLoading, setFavoriteLoading] = useState<boolean>(false);
    const [descriptionModalShow, setDescriptionModalShow] = useState<boolean>(false);
    // const [isFavoriteDisabled, setIsFavoriteDisabled] = useState<boolean>(true);
    const [isAddPdqModalOpen, setisAddPdqModalOpen] = React.useState(false);
    const [propertyDataType, setpropertyDataType] = React.useState<any>([]);
    const [PdqCipherQuery, setPdqCipherQuery] = useState<string>("");



    const handleClose = () => {
        dispatch(setRunQueryOpen())
    };

    const runQueryOpen = useAppSelector((state) => state.sidebarReducer.isRunQueryOpen)
    const dbName = localStorage.getItem('dbName');

    React.useEffect(() => {
        setQueryModalOpen(runQueryOpen)

    }, [runQueryOpen])

    React.useEffect(() => {
        if (selectedFavQuery) {
            setCipherQuery(selectedFavQuery)
        }
    }, [selectedFavQuery])

    React.useEffect(() => {
        if (cipherQuery) {
            setSelectedFavQuery(cipherQuery)
        }
    }, [cipherQuery])   

    
    const onClickAddToFavorites = async () => {
        if (cipherQuery === "") {
            CustomToast("Please enter query", {
                type: "error",
                position: "top-right",
                duration: 2000
            })
            return
        }
        if (description === "") {
            CustomToast("Please enter description", {
                type: "error",
                position: "top-right",
                duration: 2000
            })
            setDescriptionModalShow(true)
            return
        }
        setDescriptionModalShow(false)
        setFavoriteLoading(true)
        let modifiedDbName = dbName.startsWith('srmsupplyside') ? dbName.slice(0, -6) : dbName;
        
        try {
            const payload = {
                query: cipherQuery,
                description,
                viewName: modifiedDbName
            }
            const newFavorite = await createFavorite(payload)
            if (newFavorite) {
                CustomToast("Added to favorites", {
                    type: "success",
                    position: "top-right",
                    duration: 2000
                })
            dispatch(setFavoritesOpen(false))

            }
        } catch (err) {
            // setDescriptionModalShow(true)
            CustomToast("Failed to add favorite", {
                type: "error",
                position: "top-right",
                duration: 2000
            })
        }
        setFavoriteLoading(false)
        setDescription("")
        // setCipherQuery("") 
        // dispatch(setRunQueryOpen(false))

    }

    useEffect(()=>{
        const getPropertyDataType = async () => {
            const nodeQuery = `
                CALL apoc.meta.nodeTypeProperties()
                YIELD propertyName, propertyTypes, nodeLabels
                WITH apoc.text.join(nodeLabels, '.') + '.' + propertyName AS entity_property,
                    reduce(s = '', pt IN propertyTypes | 
                            CASE WHEN s = '' THEN pt ELSE s + ', ' + pt END) AS property_types
                RETURN entity_property, property_types
            `
            const relQuery = `
                CALL apoc.meta.relTypeProperties()
                YIELD propertyName, propertyTypes, relType
                WITH relType + '.' + propertyName AS entity_property,
                    reduce(s = '', pt IN propertyTypes | 
                            CASE WHEN s = '' THEN pt ELSE s + ', ' + pt END) AS property_types
                RETURN entity_property, property_types
                ORDER BY entity_property
            `
            const nodeDataType = await runCipherQuery(nodeQuery)
            const relDataType = await runCipherQuery(relQuery)

            const propertyDataType = nodeDataType.tableData?.values.concat(relDataType.tableData?.values.filter(row => row.entity_property !== null && row.property_types !== null))
            setpropertyDataType(propertyDataType)
        }
        getPropertyDataType();
    },[])

    const handleAddPdqClose = () => {
        setisAddPdqModalOpen(false)
    }

    // const handleAddPdqOpen = () =>{
    //     setisAddPdqModalOpen(true)
    //     setPdqCipherQuery(cipherQuery)
    // }


    return (
        <div data-testid="run-query-modal">
            <Modal
                open={isQueryModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography sx={{
                            fontSize: "1.25rem",
                            fontWeight: 'bold',
                            marginBottom: "1.25rem"
                        }}>
                            Cypher Query
                        </Typography>
                        <IconButton onClick={handleClose} sx={{marginBottom:"1rem"}}> 
                        <CloseOutlinedIcon />
                        </IconButton>
                    </Box>
                    <textarea rows={15} style={{
                        width: "100%",
                        borderRadius: "0.6rem",
                        backgroundColor: "#F5F5F5",
                        border: "0",
                        padding: "0.5rem",
                        resize: "none",
                    }}
                        onChange={(e) => {setCipherQuery(e.target.value); onChangeQuery()}}
                        value={cipherQuery}
                    ></textarea>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: "1rem",
                        gap: "0.5rem",
                    }}>
                        <Button variant="outlined" 
                        disabled={isFavoriteDisabled} 
                        onClick={() => {setisAddPdqModalOpen(true); setPdqCipherQuery(cipherQuery)}} 
                        sx={{textTransform: "none",}}>Add Predefined Query</Button>
                        <LoadingButton sx={{
                            color: "#461E96",
                            border: "1px solid #461E96",
                            textTransform: "none",
                            width:"9rem",
                            // fontSize: "1rem",
                            // padding: "0.9375rem 1.8125rem"
                        }}
                            variant="outlined"
                            onClick={onClickAddToFavorites}
                            loading={favoriteLoading}
                            disabled={isFavoriteDisabled}
                        >Add to favorites</LoadingButton>
                        <LoadingButton sx={{
                            color: "#ffffff",
                            border: "1px solid #461E96",
                            textTransform: "none",
                            width:"9rem",
                            // fontSize: "1rem",
                            // padding: "1.0625rem 4.375rem",
                            backgroundColor: "#461E96",
                            ":hover": { backgroundColor: "#461E96" }
                        }}
                            variant="contained"
                            loading={queryRunning}
                            onClick={() => onSubmitRun(cipherQuery)}
                        >Run</LoadingButton>
                    </Box>
                </Box>

            </Modal>
            <FavoriteQueryModal setDescription={setDescription} onClickAddToFavorites={onClickAddToFavorites} descriptionModalShow={descriptionModalShow} setDescriptionModalShow={setDescriptionModalShow} />
            <Toaster />
            <AddPredefinedQueryModal isAddPdqModalOpen={isAddPdqModalOpen} setisAddPdqModalOpen={setisAddPdqModalOpen} handleAddPdqClose={handleAddPdqClose} propertyDataType={propertyDataType} PdqCypherQuery={PdqCipherQuery}/>
        </div>
    )
}

export default RunQueryModal