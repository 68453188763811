import { Box, Button, Modal, Typography } from "@mui/material"
import { CloseOutlined } from "@mui/icons-material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30rem",
    height: "auto",
    bgcolor: '#ffffff',
    borderRadius: "0.625rem",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
    p: "1.3rem",
};

const FavoriteQueryModal = ({ onClickAddToFavorites, setDescription, descriptionModalShow, setDescriptionModalShow }) => {
    return (
        <>
            <Modal
                open={descriptionModalShow}
                onClose={() => setDescriptionModalShow(false)}
            >
                <Box sx={style}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                        sx={{
                            fontSize: "1.5rem",
                            color: "#000000",
                            mb: "1rem"
                        }}
                    >Description</Typography>
                                            <Button sx={{
                            marginBottom: "1rem",
                            fontSize: "2rem",
                            width: "auto",
                            fontWeight: 600
                        }} endIcon={<CloseOutlined />} onClick={() => setDescriptionModalShow(false)}>
                        </Button>
                    </Box>

                    <textarea
                        style={{
                            width: "100%",
                            height: "10rem",
                            borderRadius: "0.625rem",
                            // border: "1px solid #000000",
                            resize: "none",
                            padding: "0.5rem",
                            background:"rgb(245, 245, 245)"
                        }}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                    <Button sx={{
                            color: "#ffffff",
                            border: "1px solid #461E96",
                            textTransform: "none",
                            fontSize: "1rem",
                            backgroundColor: "#461E96",
                            width:"7rem",
                            float:"right",
                            marginTop:"0.5rem",
                            ":hover": { backgroundColor: "#461E96" }
                        }} onClick={onClickAddToFavorites}>
                            Submit 
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

export default FavoriteQueryModal