import { Box, Input, Button, Typography, Divider, Modal, TextField, IconButton, Chip } from '@mui/material';
import React, { ChangeEvent, useEffect } from 'react';
import { useState, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { setIsFromAddPredefinedQuery, setRunQueryOpen } from '../../redux/reducers/sidebarReducer';
import { useAppDispatch } from '../../redux/hooks/hook';
import { getFavoritesByEmail, getPredefinedQueryByEmail, runCipherQuery } from '../../api/api';
import ClearIcon from '@mui/icons-material/Clear';

interface ParamsInterface {
    name?: string,
    type?: string
}
interface PredefinedQueryInterface {
    highlighted_description: string,
    description: string,
    query: string,
    params: ParamsInterface[],
    view: string

}



const predefinedTextStyle = {
    color: '#461e96',
    fontWeight: 'bold'
}

const searchBoxData: PredefinedQueryInterface[] = [

    // =======================Supply Side Predefined queries==================================

    {
        highlighted_description: `All FDF Manufacturers who are the single source of NDC Groups in <b>$Country</b>`,
        description: `All FDF Manufacturers who are the single source of NDC Groups in $Country`,
        query: `
        MATCH p=(n:ndcGroup)-[:MANUFACTURED_BY]->(ap:FdfManufacturer) with n as n,count(distinct ap) as counts 
        where counts=1 match p3=(n)-[:MANUFACTURED_IN_FACILITY]->(fk:facilityKey) 
        MATCH p2=(n)-[:MANUFACTURED_BY]->(ap)-[:USES_FACILITY]->(fk)-[:LOCATED_IN_CITY]->(c:city)-[:LOCATED_IN_STATE]->(:state)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`{country:COUNTRY_PARAM}) 
        return p2;`,
        params: [
            {
                name: "COUNTRY_PARAM",
                type: "string"
            },
        ],
        view: 'srmsupplyside'
    },
    {
        highlighted_description: `Show the full lineage of a drug in <b>$GCN</b>`,
        description: `Show the full lineage of a drug in $GCN`,
        query: `
        MATCH p1=(g:GCN)-[r*]->(H:HIC1) where g.GCN= GCN_PARAM 
        match p2= (g)<-[:IS_SPECIFIC_QUANTITY_OF]-(pn:GPN)<-[:HAS_GPN]-(co:ndcCodes)-[b:BELONGS_TO_NDC_GROUP]-(n:ndcGroup) 
        match (n)-[:MANUFACTURED_BY]->(fd:\`FdfManufacturer\`) 
        optional match (n)-[:MANUFACTURED_BY]->(ap:\`ApiManufacturer\`) 
        match p3=(n)-[:LABELED_BY]->(:labelerName)-[:USES_MANUFACTURER]->(fd) 
        optional match p4=(fd)-[:GETS_API_FROM]->(ap) 
        match p5=(n)-[:MANUFACTURED_IN_FACILITY]->(fk:facilityKey) 
        optional match p6=(fd)-[:USES_FACILITY]->(fk) 
        optional match p11=(fk)-[:LOCATED_IN_CITY]->(:city)-[:LOCATED_IN_STATE]->(:state) -[:LOCATED_IN_COUNTRY]->(m:\`M49 Country\`) 
        optional match p7=(m)-[:LOCATED_IN_INTERMEDIATE_REGION]->(:\`M49 Intermediate_region\`)-[:BELONGS_TO_SUB_REGION]->(:\`M49 Sub_region\`) 
        optional match p8=(m)-[:LOCATED_IN_SUB_REGION]->(:\`M49 Sub_region\`) match p9=(co)<-[:HAS_NDC]-(mat:Material) match p10=(mat)-[:SOLD_BY]->(:Vendor) RETURN p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11 
        ;`,
        params: [
            {
                name: "GCN_PARAM",
                type: "string"
            }
        ],
        view: 'srmsupplyside'

    },
    {
        highlighted_description: `All Vendors who get materials from a <b>$FDF Manufacturer</b>`,
        description: `All Vendors who get materials from a $FDF Manufacturer`,
        query:
            `
        match p1 = (fdf:FdfManufacturer{FdfManufacturer:FDF_MANUFACTURER_PARAM})<-[:MANUFACTURED_BY]-(:ndcGroup)<-[:BELONGS_TO_NDC_GROUP]-(n:ndcCodes)<-[:HAS_NDC]-(:Material)-[:SOLD_BY]->(:Vendor)-[:IS_THE_LABELER]->(:labelerName)
        Optional match p2 = (n)-[:HAS_GPN]->(:GPN)-[:IS_STRENGTH_FORM_OF]->(:GCN)
        return p1, p2 ;`,
        params: [
            {
                name: "FDF_MANUFACTURER_PARAM",
                type: "string"
            }
        ],
        view: 'srmsupplyside'

    },
    {
        highlighted_description: `All Manufacturers who have a facility in a <b>$Country</b>`,
        description: `All Manufacturers who have a facility in a $Country`,
        query:
            `
        match p=()-[:USES_FACILITY]->(:facilityKey)-[:LOCATED_IN_CITY]->(:city)-[:LOCATED_IN_STATE]->(s:state)-[:LOCATED_IN_COUNTRY]->(m:\`M49 Country\`)
        where m.country=COUNTRY_PARAM
        return p ;`,
        params: [
            {
                name: "COUNTRY_PARAM",
                type: "string"
            }
        ],
        view: 'srmsupplyside'

    },
    {
        highlighted_description: "All Manufacturers who have a facility in a <b>$State</b>",
        description: "All Manufacturers who have a facility in a $State",
        query: `
        MATCH p=()-[:USES_FACILITY]->(:facilityKey)-[:LOCATED_IN_CITY]->(:city)-[:LOCATED_IN_STATE]->(s:state)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        WHERE s.state =STATE_PARAM
        OPTIONAL MATCH (s)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        RETURN p ;`,
        params: [
            {
                name: "STATE_PARAM",
                type: "string"
            }
        ],
        view: 'srmsupplyside'

    },
    {
        highlighted_description: "All Manufacturers who have a facility in a <b>$City</b>",
        description: "All Manufacturers who have a facility in a $City",
        query: `
        match p=()-[:USES_FACILITY]->(:facilityKey)-[:LOCATED_IN_CITY]->(c:city)-[:LOCATED_IN_STATE]->(:state)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        where c.city=CITY_PARAM
        optional match (c)-[:LOCATED_IN_STATE]->(:state)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        return p ;`,
        params: [
            {
                name: "CITY_PARAM",
                type: "string"
            }
        ],
        view: 'srmsupplyside'
    },

    // =======================Sell Side Predefined queries==================================

    {
        highlighted_description: `Top 5 customers across all FDC's for <b>$FiscalMonth</b>`,
        description: `Top 5 customers across all FDC's for $FiscalMonth`,
        query: `
        Match (f:\`Fiscal Month\`)
        where f.fiscalMonth=Enter_FiscalMonth_[YYYYMM]
        MATCH p2=(F:FDC)-[:SHIPS_TO]->(H:Hub)-[:DELIVERS_TO]->(R)<-[:BELONGS_TO_RGSG]-(c1)-[r*]->(c5:\`Customer 5X\`)
        MATCH (c1)-[l:LINES_CONSUMED]->(f)
        with distinct F,l,c5
        WITH F AS fdc, c5.customer5xNbr AS customer5x, SUM(l.lines) AS totalLines
        ORDER BY fdc, totalLines DESC
        WITH fdc, COLLECT({customer: customer5x,lines:totalLines}) as customers
        with fdc, customers[..5] as top5Customers
        unwind top5Customers as customer
        match (customer_5x:\`Customer 5X\`)
        where customer_5x.customer5xNbr=customer.customer
         
        CALL apoc.create.vRelationship(fdc, 'HAS_CUSTOMER',{lines:customer.lines},customer_5x) YIELD rel AS  fdc_customer5
        return  fdc,customer_5x,fdc_customer5
        `,
        params: [
            {
                name: "Enter_FiscalMonth_[YYYYMM]",
                type: "number"
            },
        ],
        view: 'srmsellside'
    },
    {
        highlighted_description: "Full lineage for <b>$Customer5x</b> along with its lines, totes and stops utilization for <b>$FiscalMonth</b>",
        description: "Full lineage for $Customer5x along with its lines, totes and stops utilization for $FiscalMonth",
        query: `
        Match (fm:\`Fiscal Month\`)
        where fm.fiscalMonth=Enter_FiscalMonth_[YYYYMM]
        match p=(fm)<-[r]-(:\`Customer 1X\`)-[:BELONGS_TO_2X]->(:\`Customer 2X\`)-[:BELONGS_TO_3X]->(:\`Customer 3X\`)-[:BELONGS_TO_4X]->(:\`Customer 4X\`)-[:BELONGS_TO_5X]->(c5:\`Customer 5X\`)
        where c5.customer5xNbr=Enter_Customer5x_Number
        with c5.customer5xNbr as c_5,sum(r.lines) as total_lines, sum(r.totes) as total_totes, sum(r.stops) as total_stops,c5
        call apoc.create.vNode(['Lines'], {lines:total_lines}) yield node as Lines_c5
        call apoc.create.vRelationship(c5,'Lines_Used',{},Lines_c5) yield rel as
        lines_used
        call apoc.create.vNode(['Totes'], {totes:total_totes}) yield node as Totes_c5
        call apoc.create.vRelationship(c5,'Totes_Used',{},Totes_c5) yield rel as
        totes_used
        call apoc.create.vNode(['Stops'], {stops:total_stops}) yield node as Stops_c5
        call apoc.create.vRelationship(c5,'Stops_Used',{},Stops_c5) yield rel as stops_used
         
        match p=(FDC:FDC)-[SHIPS_TO:SHIPS_TO]->(HUB:Hub)-[DELIVERS_TO:DELIVERS_TO]->(Rating_group)<-[BELONGS_TO_RGSG:BELONGS_TO_RGSG]-(customer_1x:\`Customer 1X\`)-[BELONGS_TO_2X:BELONGS_TO_2X]->(customer_2x:\`Customer 2X\`)-[BELONGS_TO_3X:BELONGS_TO_3X]->(customer_3x:\`Customer 3X\`)-[BELONGS_TO_4X:BELONGS_TO_4X]->(customer_4x:\`Customer 4X\`)-[BELONGS_TO_5X:BELONGS_TO_5X]->(customer_5x:\`Customer 5X\`)
        where customer_5x.customer5xNbr=Enter_Customer5x_Number
        return customer_1x,customer_2x,customer_3x,customer_4x,customer_5x,BELONGS_TO_2X,BELONGS_TO_3X,BELONGS_TO_4X,BELONGS_TO_5X,FDC,SHIPS_TO,HUB,DELIVERS_TO,Rating_group,BELONGS_TO_RGSG,c5,Lines_c5,lines_used,Totes_c5,totes_used,Stops_c5,stops_used 
        `,
        params: [
            {
                name: "Enter_Customer5x_Number",
                type: "string"
            },
            {
                name: "Enter_FiscalMonth_[YYYYMM]",
                type: "number"
            },

        ],
        view: 'srmsellside'
    },
    {
        highlighted_description: "Lines utilized by <b>$Customer5X</b> in <b>$FDC</b> for the latest month",
        description: "Lines utilized by $Customer5X in $FDC for the latest month",
        query: `
        Match p=(F:FDC)-[fh:SHIPS_TO]->(H:Hub)-[hr:DELIVERS_TO]->(R)<-[rc:BELONGS_TO_RGSG]-(c1:\`Customer 1X\`)-[r2:BELONGS_TO_2X]->(c2:\`Customer 2X\`)-[r3:BELONGS_TO_3X]->(c3:\`Customer 3X\`)-[r4:BELONGS_TO_4X]->(c4:\`Customer 4X\`)-[r5:BELONGS_TO_5X]->(c5:\`Customer 5X\`)
        where c5.customer5xNbr=Enter_Customer5x_Number and F.fdcNbr=Enter_FDC_Number
        match (f:\`Fiscal Month\`)
        with max(f.fiscalMonth) as fm,f,c1,c2,c3,c4,c5
        where f.fiscalMonth=fm
        match (f)<-[r]-(c1)-[:BELONGS_TO_2X]->(c2)-[:BELONGS_TO_3X]->(c3)-[:BELONGS_TO_4X]->(c4)-[:BELONGS_TO_5X]->(c5)
         
        with c5.customer5xNbr as c_5,sum(r.lines) as total_lines,sum(r.linesAFrame) as linesAFrame,sum(r.linesFridge) as linesFridge,sum(r.linesVault) as linesVault,sum(r.linesCage) as linesCage,sum(r.linesReserve) as linesReserve,sum(r.linesManual) as linesManual,sum(r.totes) as total_totes, sum(r.stops) as total_stops,c5 
        call apoc.create.vNode(['Lines'], {lines:total_lines,linesAFrame:linesAFrame,linesFridge:linesFridge,linesVault:linesVault,linesCage:linesCage,linesReserve:linesReserve,linesManual:linesManual}) yield node as Lines_c5
        call apoc.create.vRelationship(c5,'Lines_Used',{},Lines_c5) yield rel as
        lines_used
        call apoc.create.vNode(['Totes'], {totes:total_totes}) yield node as Totes_c5
        call apoc.create.vRelationship(c5,'Totes_Used',{},Totes_c5) yield rel as
        totes_used
        call apoc.create.vNode(['Stops'], {stops:total_stops}) yield node as Stops_c5
        call apoc.create.vRelationship(c5,'Stops_Used',{},Stops_c5) yield rel as stops_used
        match p=(FDC:FDC)-[SHIPS_TO:SHIPS_TO]->(Hub:Hub)-[DELIVERS_TO:DELIVERS_TO]->(RG)<-[BELONGS_TO_RGSG:BELONGS_TO_RGSG]-(customer_1x:\`Customer 1X\`)-[BELONGS_TO_2X:BELONGS_TO_2X]->(customer_2x:\`Customer 2X\`)-[BELONGS_TO_3X:BELONGS_TO_3X]->(customer_3x:\`Customer 3X\`)-[BELONGS_TO_4X:BELONGS_TO_4X]->(customer_4x:\`Customer 4X\`)-[BELONGS_TO_5X:BELONGS_TO_5X]->(cust5)
        where cust5.customer5xNbr=Enter_Customer5x_Number and FDC.fdcNbr=Enter_FDC_Number
        return c5,Lines_c5,lines_used,Totes_c5,totes_used,Stops_c5,stops_used,BELONGS_TO_5X,customer_4x,BELONGS_TO_4X,customer_3x,BELONGS_TO_3X,customer_2x,BELONGS_TO_2X,customer_1x,BELONGS_TO_RGSG,RG,DELIVERS_TO,Hub,SHIPS_TO,FDC
        `,
        params: [
            {
                name: "Enter_Customer5x_Number",
                type: "string"
            },
            {
                name: "Enter_FDC_Number",
                type: "string"
            },

        ],
        view: 'srmsellside'
    },
    {
        highlighted_description: "Utilization of a <b>$FDC</b> and its hubs for the latest month",
        description: "Utilization of a $FDC and its hubs for the latest month",
        query: `
        match (f:\`Fiscal Month\`)
        with max(f.fiscalMonth) as fm,f
        where f.fiscalMonth=fm
        MATCH p=(F:FDC)-[:SHIPS_TO]->(H:Hub)-[:DELIVERS_TO]->(R)<-[:BELONGS_TO_RGSG]-(c1)-[r]->(f)
        where F.fdcNbr=Enter_FDC_Number
        with distinct F,r,f 
        with F.fdcNbr as FD,sum(r.lines) as total_lines,sum(r.linesAFrame) as linesAFrame,sum(r.linesFridge) as linesFridge,sum(r.linesVault) as linesVault,sum(r.linesCage) as linesCage,sum(r.linesReserve) as linesReserve,sum(r.linesManual) as linesManual,sum(r.totes) as total_totes, sum(r.stops) as total_stops,F,f
        call apoc.create.vNode(['Lines_FDC'], {lines:total_lines,linesAFrame:linesAFrame,linesFridge:linesFridge,linesVault:linesVault,linesCage:linesCage,linesReserve:linesReserve,linesManual:linesManual}) yield node as Lines_fdc
        call apoc.create.vRelationship(F,'Lines_Used',{},Lines_fdc) yield rel as
        lines_used
        call apoc.create.vNode(['Totes_FDC'], {totes:total_totes}) yield node as Totes_fdc
        call apoc.create.vRelationship(F,'Totes_Used',{},Totes_fdc) yield rel as
        totes_used
        call apoc.create.vNode(['Stops_FDC'], {stops:total_stops}) yield node as Stops_fdc
        call apoc.create.vRelationship(F,'Stops_Used',{},Stops_fdc) yield rel as stops_used
        MATCH p=(F)-[SHIPS_TO:SHIPS_TO]->(Hub:Hub)-[DELIVERS_TO:DELIVERS_TO]->(R)<-[:BELONGS_TO_RGSG]-(customer1)-[rn]->(f)
        with Hub.hub as Hubs,sum(rn.lines) as total_hublines,sum(rn.linesAFrame) as hub_linesAFrame,sum(rn.linesFridge) as hub_linesFridge,sum(rn.linesVault) as hub_linesVault,sum(rn.linesCage) as hub_linesCage,sum(rn.linesReserve) as hub_linesReserve,sum(rn.linesManual) as hub_linesManual,sum(rn.totes) as total_totes_hub, sum(rn.stops) as total_stops_hub,Hub,Lines_fdc,lines_used,Totes_fdc,totes_used,Stops_fdc,stops_used,SHIPS_TO,F
        call apoc.create.vNode(['Lines_Hub'], {lines:total_hublines,linesAFrame:hub_linesAFrame,linesFridge:hub_linesFridge,linesVault:hub_linesVault,linesCage:hub_linesCage,linesReserve:hub_linesReserve,linesManual:hub_linesManual}) yield node as Lines_hub
        call apoc.create.vRelationship(Hub,'Lines_Used',{},Lines_hub) yield rel as
        lines_used_hub
        call apoc.create.vNode(['Totes_Hub'], {totes:total_totes_hub}) yield node as Totes_hub
        call apoc.create.vRelationship(Hub,'Totes_Used',{},Totes_hub) yield rel as
        totes_used_hub
        call apoc.create.vNode(['Stops_Hub'], {stops:total_stops_hub}) yield node as Stops_hub
        call apoc.create.vRelationship(Hub,'Stops_Used',{},Stops_hub) yield rel as stops_used_hub
        return  F,Lines_fdc,lines_used,Totes_fdc,totes_used,Stops_fdc,stops_used,SHIPS_TO,Hub,Lines_hub,Totes_hub,Stops_hub,stops_used_hub,totes_used_hub,lines_used_hub
        `,
        params: [
            {
                name: "Enter_FDC_Number",
                type: "string"
            },

        ],
        view: 'srmsellside'
    },
    {
        highlighted_description: "Location of a <b>$FDC</b> and its Hubs",
        description: "Location of a $FDC and its Hubs",
        query: `
        MATCH p1=(Hub:Hub)<-[:SHIPS_TO]-(FDC:FDC)-[:LOCATED_IN_CITY]->(:City)-[:LOCATED_IN_STATE]->(:State)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        where FDC.fdcNbr=Enter_FDC_Number
        MATCH p2=(Hub)-[:LOCATED_IN_CITY]->(:City)-[:LOCATED_IN_STATE]->(:State)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)   
        RETURN p1,p2
        `,
        params: [
            {
                name: "Enter_FDC_Number",
                type: "string"
            },

        ],
        view: 'srmsellside'
    },
    {
        highlighted_description: "Location of all customers under a <b>$Customer5x</b>",
        description: "Location of all customers under a $Customer5x",
        query: `
        Match p1=(c1)-[r*]->(c5:\`Customer 5X\`)
        where c5.customer5xNbr=Enter_Customer5x_Number
        match p2=(c1)-[:LOCATED_IN_CITY]->(:City)-[:LOCATED_IN_STATE]->(:State)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        return p1,p2
        `,
        // Match p1=(c1:\`Customer 1X\`)-[BELONGS_TO_2X:BELONGS_TO_2X]->(c2:\`Customer 2X\`)-[BELONGS_TO_3X:BELONGS_TO_3X]->(c3:\`Customer 3X\`)-[BELONGS_TO_4X:BELONGS_TO_4X]->(c4:\`Customer 4X\`)-[BELONGS_TO_5X:BELONGS_TO_5X]->(c5:\`Customer 5X\`)
        // where c5.customer5xNbr=Enter_Customer5x_Number
        // match p2=(c1)-[LOCATED_IN_CITY:LOCATED_IN_CITY]->(City:City)-[LOCATED_IN_STATE:LOCATED_IN_STATE]->(State:State)-[LOCATED_IN_COUNTRY:LOCATED_IN_COUNTRY]->(country:\`M49 Country\`)

        // return c1,BELONGS_TO_2X,c2,BELONGS_TO_3X,c3,BELONGS_TO_4X,c4,BELONGS_TO_5X,c5,LOCATED_IN_CITY,City,LOCATED_IN_STATE,State,LOCATED_IN_COUNTRY,country

        params: [
            {
                name: "Enter_Customer5x_Number",
                type: "string"
            },

        ],
        view: 'srmsellside'
    },
    {
        highlighted_description: "FDCs and Hubs  present in <b>$State</b>",
        description: "FDCs and Hubs  present in $State",
        query: `
        match(state:State)
        where state.state=Enter_State
        match p1=(FDC:FDC)-[:LOCATED_IN_CITY]-(:City)-[:LOCATED_IN_STATE]->(state)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        match p2=(:Hub)-[:LOCATED_IN_CITY]-(:City)-[:LOCATED_IN_STATE]->(state)-[:LOCATED_IN_COUNTRY]->(:\`M49 Country\`)
        return p1,p2
        `,
        params: [
            {
                name: "Enter_State",
                type: "string"
            },

        ],
        view: 'srmsellside'
    },
    {
        highlighted_description: "Percentage of lines, stops and totes utilization by Customers 5Xs at a <b>$FDC</b> for a <b>$fiscal month</b>",
        description: "Percentage of lines, stops and totes utilization by Customers 5Xs at a $FDC for a $FiscalMonth",
        query: `
        MATCH p=(F:FDC)-[:SHIPS_TO]->(:Hub)-[:DELIVERS_TO]->(:\`Rating Signature Group\`)<-[:BELONGS_TO_RGSG]-(c1)-[r]->(fm:\`Fiscal Month\`)
        where F.fdcNbr=Enter_FDC_Number and fm.fiscalMonth=Enter_FiscalMonth_[YYYYMM]
        with distinct F,r
        with F.fdcNbr as fdc,sum(r.lines) as total_lines,sum(r.stops) as total_stops,sum(r.totes) as total_totes,F
        call apoc.create.vNode(['Lines_FDC'], {lines:total_lines}) yield node as Lines_fdc
        call apoc.create.vRelationship(F,'Lines_Used',{},Lines_fdc) yield rel as lines_fdc_used
        call apoc.create.vNode(['Totes_FDC'], {totes:total_totes}) yield node as Totes_fdc
        call apoc.create.vRelationship(F,'Totes_Used',{},Totes_fdc) yield rel as
        totes_fdc_used
        call apoc.create.vNode(['Stops_FDC'], {stops:total_stops}) yield node as Stops_fdc
        call apoc.create.vRelationship(F,'Stops_Used',{},Stops_fdc) yield rel as stops_fdc_used
        
        MATCH p2=(F)-[:SHIPS_TO]->(:Hub)-[:DELIVERS_TO]->(R)<-[:BELONGS_TO_RGSG]-(cust1:\`Customer 1X\`)-[r*]->(cust5:\`Customer 5X\`)
        match (cust1)-[l]->(:\`Fiscal Month\`)
        with distinct F,l,Lines_fdc,lines_fdc_used,Totes_fdc,totes_fdc_used,Stops_fdc,stops_fdc_used,total_totes,total_lines,total_stops,cust5
        with F.fdcNbr as fdc,cust5.customer5xNbr as c55,sum(l.lines) as lines,total_lines,round(sum(l.lines)/total_lines *100,3) as lines_percent ,sum(l.totes) as totes,total_totes
        ,round(sum(l.totes)/total_totes *100,2) as totes_percent,sum(l.stops) as stops,total_stops,round(sum(l.stops)/total_stops *100,2) as stops_percent,cust5,F,Lines_fdc,lines_fdc_used,Totes_fdc,totes_fdc_used,Stops_fdc,stops_fdc_used
        
        call apoc.create.vRelationship(F,'HAS_CUSTOMER_5X',{},cust5) yield rel as Fdc_cust
        
        
        call apoc.create.vNode(['Lines_Customer5x'], {lines:lines,percentage:lines_percent}) yield node as Lines
        call apoc.create.vRelationship(cust5,'Lines_Used',{},Lines) yield rel as lines_used
        call apoc.create.vNode(['Totes_Customer5x'], {totes:totes,percentage:totes_percent}) yield node as Totes
        call apoc.create.vRelationship(cust5,'Totes_Used',{},Totes) yield rel as
        totes_used
        call apoc.create.vNode(['Stops_Customer5x'], {stops:stops,percentage:stops_percent}) yield node as Stops
        call apoc.create.vRelationship(cust5,'Stops_Used',{},Stops) yield rel as stops_used
        return cust5,Lines,lines_used,Totes,totes_used,Stops,stops_used,F,Lines_fdc,lines_fdc_used,Totes_fdc,totes_fdc_used,Stops_fdc,stops_fdc_used,Fdc_cust       
        
        `,
        params: [
            {
                name: "Enter_FDC_Number",
                type: "string"
            },
            {
                name: "Enter_FiscalMonth_[YYYYMM]",
                type: "number"
            },

        ],
        view: 'srmsellside'
    },
]

export default function SearchInput({ handleOnClickRun, queryRunning, setSelectedFavQuery, onChangeQuery, selectedFavQuery, handleSearchChange, nodes }: any) {
    const [predefinedQueryBoxShow, setPredefinedQueryBoxShow] = useState<boolean>(false)
    const predefinedQueryBoxRef = useRef(null)
    const [selectedQueryItem, setSelectedQueryItem] = useState(null)
    const [queryParams, setQueryParams] = useState<any>([]);
    const [isQueryModalOpen, setQueryModalOpen] = React.useState(false);
    const [selectedQueryDesc, setSelectedQueryDesc] = useState<string>("");
    const [filteredViews, setFilteredViews] = useState<PredefinedQueryInterface[]>(searchBoxData);
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [new_PDQ, setNew_PDQ] = useState<any>((""))

    const [PDQ, setPDQ] = useState<any>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [PdqNodes, setPdqNodes] = useState<any>([]);
    const [PdqRelationship, setPdqRelationship] = useState<any>([]);
    const [Favorites, setFavorites] = useState<any>([]);
    const [SearchThroughGraph, setSearchThroughGraph] = useState<boolean>(false);

    const dbName = localStorage.getItem('dbName');



    const handleClose = () => {
        setQueryModalOpen(false)
    }

    useEffect(() => {
        if (!queryRunning) {
            setQueryModalOpen(false)
        }
    }, [queryRunning])

    useEffect(() => {
        const dbName = localStorage.getItem('dbName');

        const fetchPredefinedQuery = async () => {
            const pdq = await getPredefinedQueryByEmail()
            const filteredData = pdq?.filter(item =>
                (dbName.includes(item.viewName) || dbName === '')
            );
            setPDQ(filteredData)
        }

        const fetchNodeRelaltionship = async () => {
            const nodeQuery = `
                MATCH (n)
                WITH DISTINCT labels(n) AS labelArray
                UNWIND labelArray AS label
                RETURN DISTINCT label
                // MATCH (n) RETURN distinct(labels(n))  as labels
                `
            const relationshipsQuery = `MATCH p=()-[r]->() RETURN distinct (type(r))  as relationship`
            const nodeResponse = await runCipherQuery(nodeQuery)
            const relResponse = await runCipherQuery(relationshipsQuery)
            setPdqNodes(nodeResponse.graphData)
            setPdqRelationship(relResponse.graphData)

        }
        const fetchFavorites = async () => {
            // setFavLoading(true)
            const favoritesResult = await getFavoritesByEmail()
            if (favoritesResult) {
                let modifiedDbName = dbName && dbName.startsWith('srmsupplyside') ? dbName.slice(0, -6) : dbName;
                const DB_filteredFavorites = favoritesResult.filter(
                    (eachFavorite) =>
                        eachFavorite.viewName === modifiedDbName &&
                        eachFavorite.description.toLowerCase().includes(searchInput.toLowerCase())
                );
                setFavorites(DB_filteredFavorites)
            }
            else {
                setFavorites([])
            }
            // setFavLoading(false)
        }

        fetchPredefinedQuery();
        fetchNodeRelaltionship();
        fetchFavorites();





        // const getPredefined_Q = async () => {

        //     const query =
        //         `use graphexploreroptions
        //     match (u:User{email:"v558299@amerisourcebergen.com"})-[:HAS_PREDEFINED_QUERY]->(p:Predefined_Query)
        //     return p`

        //     const predefined_Q = await runCipherQuery(query)
        //     const graphDataArray = predefined_Q.graphData;

        //     setNew_PDQ(graphDataArray)
        // }
        // getPredefined_Q();

        if (dbName) {
            const filteredData = searchBoxData.filter(item => dbName.includes(item.view));
            setFilteredViews(filteredData);
        }
    }, []);

    const filteredPDQ = PDQ?.filter((item) =>
        item.description.toLowerCase().includes(searchInput?.toLowerCase())
    );
    const filteredNodes = PdqNodes?.filter((item) =>
        item.toLowerCase().includes(searchInput?.toLowerCase())
    );
    const filteredRel = PdqRelationship?.filter((item) =>
        item.toLowerCase().includes(searchInput?.toLowerCase())
    );
    const filteredFavorites = Favorites.filter((fav) =>
        fav.description.toLowerCase().includes(searchInput?.toLowerCase())
      );


    /**
     * To change the query with the given parameters value and call run
     * @param paramsObject 
     */
    const dispatch = useAppDispatch()
    const onClickRun = (paramsObject) => {
        const newQuery = constructNewQuery(paramsObject, selectedQueryItem.query)
        handleOnClickRun(newQuery)
        setSelectedFavQuery(newQuery)
        // dispatch(setRunQueryOpen(true))  
    }

    /**
     * Handle predefined query click
     * @param item 
     * On click of predefined query item update the state of selected query item and hide the predefined query box
     */ 
    const handlePredefinedQueryClick = (item: any, type: string) => {
        setPredefinedQueryBoxShow(false);
// ===========================cases================
        switch(type){
            case 'predefinedQuery':
                setSelectedQueryItem(item)
                setSearchInput(item?.description)
                setSearchThroughGraph(false)
                break;
            case 'nodes':
                const nodeQuery = `MATCH (n:\`${item}\`) RETURN n`
                setSearchInput(item)
                setSelectedFavQuery(nodeQuery)
                setSelectedQueryItem(null)
                setSearchThroughGraph(false)
                break;
            case 'relationship':
                const relationshipQuery = `MATCH p=()-[r:\`${item}\`]->() RETURN p`
                setSearchInput(item)
                setSelectedFavQuery(relationshipQuery)
                setSelectedQueryItem(null)
                setSearchThroughGraph(false)
                break;
            case 'favorites':
                setSearchInput(item?.description)
                setSelectedFavQuery(item?.query)
                setSelectedQueryItem(null)
                setSearchThroughGraph(false)
        }
    };
    // handle search query

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPredefinedQueryBoxShow(true)
        const value = e.target.value.toLowerCase();
        setSearchInput(value);
        setIsSearching(true);
        setSearchThroughGraph(true)
    }

        /**
     * Handle search click
     * On click search icon update the state of query params  to show the query input modal if query has parameters
     * Else if query has no parameters call the run directly
     */
        const handleSearchClick = () => {
            if (!SearchThroughGraph && selectedQueryItem?.parameter.length > 0) {
    
                //convertion array response into objest
                let params = selectedQueryItem.parameter
                let param_type = selectedQueryItem.parameter_type
                const paramsArray = params.map((label, index) => ({
                    parameter: label,
                    parameter_type: param_type[index]
                }));
                setQueryModalOpen(true)
    
                setQueryParams(paramsArray)
            } else {
                searchInput.trim().length > 0 && SearchThroughGraph && handleSearchChange(searchInput)
                onChangeQuery()
                !SearchThroughGraph && selectedFavQuery && dispatch(setRunQueryOpen(true))
                dispatch(setIsFromAddPredefinedQuery(false))
                
            }
            // Hide the predefined query box
            setPredefinedQueryBoxShow(false)
        };


    useEffect(() => {
        window.addEventListener("mousedown", handleOutsideClick)
        return () => {
            window.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const handleOutsideClick = (e) => {
        if (predefinedQueryBoxRef.current && !predefinedQueryBoxRef.current.contains(e.target)) {
            setPredefinedQueryBoxShow(false)
            setIsSearching(false)
            setSelectedQueryDesc('');
            setFilteredViews(searchBoxData.filter(item => dbName.includes(item.view)));


        }

    }

    const constructNewQuery = (params: any, query: string) => {
        let newQuery = query
        Object.keys(params).forEach((key) => {
            const { value, type } = params[key]
            newQuery = newQuery.replaceAll(
                `${key}`,
                `${type === "string" || type === "String" ? `'${value}'` : value}`
            );
        });
        return newQuery
    };
    const handleClear = () => {
        setSearchThroughGraph(true)
        setSearchInput('');
        // setSelectedFavQuery('null')
        
      };
      
    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "6rem"
            }}
                className="second-step"
                data-testid="predefined-search-input"
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <Input
                        value={searchInput}
                        // type="search"
                        sx={{
                            // paddingLeft: isSearching ? "0" : "1rem",
                            paddingLeft:"1rem",
                            paddingRight:"1rem",
                            height: "3.1rem",
                            width: "28rem",
                            borderRadius: "0px",
                            border: "1px solid #E6E6E6",
                            borderLeft: "none",
                            backgroundColor: "white",
                        }}
                        endAdornment={
                            searchInput && (
                              <IconButton onClick={handleClear} size='small'>
                                <ClearIcon fontSize='small' sx={{color:"#461e96"}}/>
                              </IconButton>
                            )
                          }
 
                        disableUnderline={true}
                        placeholder="Search"
                        onChange={(e) => handleChangeSearch(e)}
                        onClick={() => { setIsSearching(true) }}
                    />
                    <Button
                        variant="text"
                        sx={{
                            border: "0px",
                            borderRadius: "0px",
                            backgroundColor: "#461E96",
                            ":hover": { backgroundColor: "#461E96" },
                            color: "white",
                            height: "3.1rem",
                        }}
                        onClick={handleSearchClick}
                    >
                        <SearchIcon />
                    </Button>
                </Box>
                {searchInput && predefinedQueryBoxShow && (
                    <Box sx={{
                        width: "32rem",
                        maxHeight: "20rem",
                        backgroundColor: "white",
                        marginTop: "0.5rem",
                        borderRadius: "0rem 0rem 0.625rem 0.625rem",
                        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "1rem",
                        overflowY: "scroll",
                    }}
                        ref={predefinedQueryBoxRef}
                    >
                        <Typography sx={{
                            fontSize: "0.9rem", 
                            color: "#808080"
                        }}>Searches are performed exclusively within the <b style={{color:"#461e96"}}>View: {dbName}</b></Typography>
                        <Box width="100%" sx={{ marginTop: "1rem" }}>
                            {
                                filteredPDQ && filteredPDQ.length > 0 && (
                                    <Box sx={{ padding: "1rem", border: "1px solid #461e96", borderRadius: "5px", marginBottom: "1rem" }}>
                                    <Typography color={"#461e96"}>Predefined Query</Typography> 
                                    {
                                        filteredPDQ.map((eachItem, index) => (
    
                                            <><Typography sx={{
                                                marginBottom: ".7rem",
                                                marginTop: ".7rem",
                                                cursor: "pointer"
                                            }} key={index}
                                                onClick={() => handlePredefinedQueryClick(eachItem,'predefinedQuery')}
                                            >
                                                <Typography sx={{
                                                    fontSize: "0.875rem",
                                                    color: "#333333",
                                                    fontWeight: 500,
                                                    cursor: "pointer"
                                                }}
                                                    dangerouslySetInnerHTML={{ __html: eachItem?.description }}>
    
                                                </Typography>
                                            </Typography>
                                            { index < filteredPDQ.length-1 && <Divider /> }
                                            </>
    
                                        ))
                                    }
                                </Box>
                                )
                            }

                            {
                                ((filteredNodes && filteredNodes.length > 0)  || (filteredRel && filteredRel.length > 0)) && (
                                    <Box sx={{ padding: "1rem", border: "1px solid #461e96", borderRadius: "5px", marginBottom: "1rem",
                                        display:"flex", flexDirection:"column", gap:".7rem"
                                     }}>
                                        <Typography color={"#461e96"}>Nodes and Relationship</Typography>
        
                                        {
        
                                                filteredNodes.map((item, index) => (
                                                    <><Chip label={item} sx={{ width: "fit-content" }} key={index}
                                                    onClick={() => handlePredefinedQueryClick(item,'nodes')} variant="outlined" />
                                                    { index < filteredNodes.length-1 && <Divider /> }
                                                    </>
            
                                                ))
                                        }
                                        {
                                            filteredRel.map((item, index) => (
                                                <><Chip label={item} sx={{ borderRadius: "0", width: "fit-content" }} key={index} 
                                                onClick={() => handlePredefinedQueryClick(item,'relationship')} variant="outlined" />
                                                { index < filteredRel.length-1 && <Divider /> }
                                                </>
        
                                            ))
                                        }
                                    </Box>
                                )
                            }
    
                            {
                                filteredFavorites && filteredFavorites.length > 0 && (
                                    <Box sx={{ padding: "1rem", border: "1px solid #461e96", borderRadius: "5px", marginBottom: "1rem" }}>
                                    <Typography color={"#461e96"}>Favorites</Typography>
                                    {
                                            filteredFavorites.map((item, index) => (
                                                <div key={index}>
                                                    <Typography
                                                        sx={{
                                                            marginBottom: ".7rem",
                                                            marginTop: ".7rem",
                                                            cursor: "pointer",
                                                            color: "#333333",
                                                        }}
                                                        key={index}
                                                    onClick={() => handlePredefinedQueryClick(item,'favorites')}
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                    {index < filteredFavorites.length - 1 && <Divider />}
                                                </div>
                                            ))
                                    }
    
                                
                                </Box>
                                )
                            }


                            {
                                    filteredNodes.length == 0 && filteredRel.length == 0 && filteredFavorites.length == 0 && filteredPDQ.length == 0 &&
                                    <Typography
                                    sx={{
                                        marginBottom: ".7rem",
                                        marginTop: ".7rem",
                                        cursor: "pointer",
                                        color: "#333333",
                                        textAlign:"center"
                                    }}
                                >
                                    No predefined query, node, relationship, or favorite matches found. 
                                    {nodes.length>0 && <>Click <b>"Search"</b> to explore the rendered graph</>} 
                                    
                                </Typography>
                                }

                        </Box>
                    </Box>
                )}
            </Box>
            <QueryInputModal isQueryModalOpen={isQueryModalOpen} handleClose={handleClose} paramsArray={queryParams} queryRunning={queryRunning} onClickRun={onClickRun} />
        </>
    );
}


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30rem",
    height: "auto",
    bgcolor: '#ffffff',
    borderRadius: "0.625rem",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
    p: "1.3rem",
};

const QueryInputModal = ({ isQueryModalOpen, handleClose, paramsArray, onClickRun, queryRunning }: any) => {
    const [finalParams, setFinalParams] = useState<any>({})

    const handleInputParamChange = (param, e) => {
        setFinalParams({
            ...finalParams, [param.parameter]: {
                value: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                type: param?.parameter_type
            }

        })
    }


    return (
        <Modal
            open={isQueryModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography sx={{
                            color: "#333333",
                            fontSize: "1.25rem",
                            fontWeight: 500,
                            marginBottom: "1.25rem"
                        }}>
                            Enter the parameters
                        </Typography>
                        {
                            paramsArray.map((eachParam, index) => (
                                <Box key={index} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "100%",
                                }}>
                                    <TextField
                                        id="outlined-basic"
                                        label={eachParam.parameter}
                                        variant="outlined"
                                        sx={{
                                            width: "80%",
                                            marginBottom: "1rem"
                                        }}
                                        onChange={(e) => handleInputParamChange(eachParam, e)}
                                    />
                                    <Typography sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 700,
                                        marginBottom: "1rem",
                                        marginLeft: "1rem",
                                        width: "20%",
                                        textAlign: "center",
                                        border: "1px solid #d9d9d9",
                                        borderRadius: "0.625rem",
                                        padding: "0.25rem",
                                        backgroundColor: "#F2F2F2",
                                    }}>
                                        {eachParam?.parameter_type}

                                    </Typography>
                                </Box>
                            ))
                        }

                        <LoadingButton sx={{
                            backgroundColor: "#461E96",
                            color: "white",
                            width: "50%",
                            height: "3rem",
                            fontSize: "1rem",
                            fontWeight: 600,
                            textTransform: "none"
                        }}
                            onClick={() => onClickRun(finalParams)}
                            variant="contained"
                            loading={queryRunning}
                        >Run</LoadingButton>
                    </Box>
                }
            </Box>
        </Modal>
    )
}