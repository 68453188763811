import * as React from 'react';
import { Box, Button, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useEffect, useMemo, useState } from 'react';
import { runCipherQuery } from '../../api/api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../redux/hooks/hook';
import DownloadIcon from '@mui/icons-material/Download';
import { useCSVDownloader } from 'react-papaparse';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';

const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#ffffff',
  borderRadius: "0.625rem",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
  p: "3vh",
  width: "85%",
  height: "90%",
  display: "flex",
  flexDirection: "column",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#461e96',
    color: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



export default function TableExport({ open, close }) {
  const [tableRow, setData] = useState<any>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const newData = useAppSelector((state) => state.tableDataReducer.data)
  const { CSVDownloader, Type } = useCSVDownloader();
  const [searchInput, setSearchInput] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);
  const [originalData, setOriginalData] = useState<any[]>([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        

        const tableData = newData
        
        // ================================ return as node ===============================
        const value_keys = Object.keys(tableData.values[0])

        let properties = []
        let data = []
        for (let i = 0; i < value_keys.length; i++) {
          let label = tableData.values[0][value_keys[i]].labels ? tableData.values[0][value_keys[i]].labels[0] : tableData.values[0][value_keys[i]].type;
          let props = Object.keys(tableData.values[0][value_keys[i]].properties);
          props.forEach(prop => {
            properties.push(`${label}.${prop}`);
          });
        }

        for (let i = 0; i < tableData.values.length; i++) {
          let rowData = {};
          for (let j = 0; j < value_keys.length; j++) {
            let label = tableData.values[i][value_keys[j]].labels ? tableData.values[i][value_keys[j]].labels[0] : tableData.values[i][value_keys[j]].type;
            let props = tableData.values[i][value_keys[j]].properties;
            for (let prop in props) {
              rowData[`${label}.${prop}`] = props[prop];
            }
          }
          data.push(rowData);
        }


        setColumns(properties)
        setData(data);
        setOriginalData(data);

      }
      catch {
        const tableData = newData.values
        const extractPropertiesFromResponse = (responseArray) => {
          if (!Array.isArray(responseArray)) {
            throw new Error("Expected an array as input");
          }

          const allProperties = [];
          let convertedObjects = [];

          responseArray.forEach((response, index) => {
            const propertiesArray = [];

            for (const key in response) {
              if (response[key] && response[key].start) {
                const start = response[key].start
                const start_label = start.labels[0];
                const startProps = start.properties;

                for (const propKey in startProps) {
                  if (typeof startProps[propKey] === 'object' && startProps[propKey] !== null) {
                    if (Array.isArray(startProps[propKey])) {
                      propertiesArray.push({ key: `${start_label}.${propKey}`, value: startProps[propKey] });
                    } else {
                      for (const nestedKey in startProps[propKey]) {
                        propertiesArray.push({ key: `${start_label}.${propKey}`, value: startProps[propKey].low });
                      }
                    }
                  } else {
                    propertiesArray.push({ key: `${start_label}.${propKey}`, value: startProps[propKey] });
                  }
                }
              }
              if (response[key] && response[key].end) {
                const end = response[key].end
                const end_label = end.labels[0];
                const endProps = end.properties;

                for (const propKey in endProps) {
                  if (typeof endProps[propKey] === 'object' && endProps[propKey] !== null) {
                    if (Array.isArray(endProps[propKey])) {
                      propertiesArray.push({ key: `${end_label}.${propKey}`, value: endProps[propKey] });
                    } else {
                      for (const nestedKey in endProps[propKey]) {
                        propertiesArray.push({ key: `${end_label}.${propKey}`, value: endProps[propKey].low });
                      }
                    }
                  } else {
                    propertiesArray.push({ key: `${end_label}.${propKey}`, value: endProps[propKey] });
                  }
                }
              }
              if (response[key] && response[key].segments) {
                response[key].segments.forEach(segment => {
                  // Renaming based on labels
                  const start_label = segment.start.labels[0];
                  const end_label = segment.end.labels[0];
                  const startProps = segment.start.properties;
                  const endProps = segment.end.properties;
                  const relProps = segment.relationship.properties
                  const rel_label = segment.relationship.type

                  for (const propKey in startProps) {
                    if (typeof startProps[propKey] === 'object' && startProps[propKey] !== null) {
                      if (Array.isArray(startProps[propKey])) {
                        propertiesArray.push({ key: `${start_label}.${propKey}`, value: startProps[propKey] });
                      } else {
                        for (const nestedKey in startProps[propKey]) {
                          propertiesArray.push({ key: `${start_label}.${propKey}`, value: startProps[propKey].low });
                        }
                      }
                    } else {
                      propertiesArray.push({ key: `${start_label}.${propKey}`, value: startProps[propKey] });
                    }
                  }

                  for (const propKey in endProps) {
                    if (typeof endProps[propKey] === 'object' && endProps[propKey] !== null) {
                      if (Array.isArray(endProps[propKey])) {
                        propertiesArray.push({ key: `${end_label}.${propKey}`, value: endProps[propKey] });
                      } else {
                        for (const nestedKey in endProps[propKey]) {
                          propertiesArray.push({ key: `${end_label}.${propKey}`, value: endProps[propKey].low });
                        }
                      }
                    } else {
                      propertiesArray.push({ key: `${end_label}.${propKey}`, value: endProps[propKey] });
                    }
                  }
                  for (const propKey in relProps) {
                    if (typeof relProps[propKey] === 'object' && relProps[propKey] !== null) {
                      if (Array.isArray(relProps[propKey])) {
                        propertiesArray.push({ key: `${rel_label}.${propKey}`, value: relProps[propKey] });
                      } else {
                        for (const nestedKey in relProps[propKey]) {
                          propertiesArray.push({ key: `${rel_label}.${propKey}`, value: relProps[propKey].low });
                        }
                      }
                    } else {
                      propertiesArray.push({ key: `${rel_label}.${propKey}`, value: relProps[propKey] });
                    }
                  }
                });
              }
            }

            allProperties[index] = propertiesArray;
          });

          // Iterate over each array in allProperties
          for (let i = 0; i < allProperties.length; i++) {
            let newObj = {};

            // Iterate over each object in the current nested array
            for (let j = 0; j < allProperties[i].length; j++) {
              const key = allProperties[i][j].key;
              const value = allProperties[i][j].value;
              newObj[key] = value; // Assign key-value pairs to newObj
            }

            convertedObjects.push(newObj); // Push newObj into convertedObjects array
          }

          // Get unique keys
          const uniqueKeys = new Set();
          allProperties.forEach(propertiesArray => {
            propertiesArray.forEach(item => {
              uniqueKeys.add(item.key);
            });
          });

          // Convert Set to array for easier manipulation if needed
          const uniqueKeysArray = Array.from(uniqueKeys);

          return { propertiesArray: convertedObjects, uniqueKeys: uniqueKeysArray };
        };



        const { propertiesArray, uniqueKeys } = extractPropertiesFromResponse(tableData);
        setColumns(uniqueKeys as any);
        setData(propertiesArray);
        setOriginalData(propertiesArray);


      }
    };



    fetchData();
  }, [newData]);


  const sortedRows = useMemo(() => {
    if (sortConfig !== null) {
      if (sortConfig.direction === 'original') {
        return originalData;
      }
      return [...tableRow].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return tableRow;
  }, [tableRow, sortConfig, originalData]);

  const filteredRows = useMemo(() => {
    return sortedRows.filter(row => {
      return columns.some(column => {
        return row[column]?.toString().toLowerCase().includes(searchInput.toLowerCase());
      });
    });
  }, [sortedRows, columns, searchInput]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        direction = 'descending';
      } else if (sortConfig.direction === 'descending') {
        direction = 'original';
      }
    }
    setSortConfig({ key, direction });
  };

  const renderCellContent = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  return (
    <Modal open={open} onClose={close}>
      <Box sx={style1}>
        <Box display="flex" mb={1} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
            Table View
          </Typography>
          <Box>
            <IconButton onClick={close}>
              <CloseOutlinedIcon sx={{ fontSize: '1.3rem', fontWeight: 600, cursor: 'pointer' }} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TextField
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search..."
            variant="outlined"
            size="small"
            sx={{ marginBottom: '1rem', width: '20rem' }}
            type='search'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer component={Paper} sx={{height:"100%"}}>
          <Table sx={{ minWidth: 650, overflow:"auto" }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((column, ind) => (
                  <StyledTableCell
                    key={ind}
                    onClick={() => requestSort(column)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Box display="flex" alignItems="center">
                      {column}
                      {sortConfig?.key === column ? (
                        sortConfig.direction === 'ascending' ? <ArrowUpwardIcon fontSize="small" /> :
                          sortConfig.direction === 'descending' ? <ArrowDownwardIcon fontSize="small" /> : <UnfoldMoreIcon fontSize="small" />
                      ) : <UnfoldMoreIcon fontSize="small" />}
                    </Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  {columns.map((column, index) => (
                    <StyledTableCell key={index}>
                      {renderCellContent(row[column])}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {filteredRows.length == 0 && <Typography sx={{ 
          p: "1rem", 
          position:"absolute",
          top:"50%",
          left:"46%",
          zIndex:1000,
         }}>No data found</Typography>}
        
        <Box sx={{
          display: "flex", marginTop: "1rem", alignItems: "center", justifyContent: "space-between", 
        }}>
          <Typography><b>*</b>The table above has been reconstructed from the underlying graph rendered on the previous screen.</Typography>
          <Typography>Total rows: {filteredRows.length}</Typography>
          
          <Button variant="contained" startIcon={<DownloadIcon />} sx={{ paddingLeft: "1rem", paddingRight: "1rem", width: "13%", flexWrap:"wrap" }}>
            <CSVDownloader
              filename={'graph_table_data'}
              bom={true}
              config={{ delimiter: ',' }}
              data={tableRow}
            >
              <span style={{ color: "white" }}>Export as CSV</span>
            </CSVDownloader>
          </Button>
        </Box>


      </Box>
    </Modal>
  );
}

