import { Box, Fab, IconButton, Input, Modal, Tooltip, Typography } from "@mui/material"
import { useState, useMemo, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/hook";
import RunQueryModal from "../../../components/runQueryModal/RunQueryModal";
import { BasicNode, BasicRelationship } from "../../../common";
import { setGraphNodes, setGraphRelationships } from "../../../redux/reducers/graphReducer";
import ConnectedGraphVisualizer from "../../../components/graphVisualization/GraphVisualizer/ConnectedGraphVisualizer";
import { GraphStyleModel } from "../../../components/graphVisualization";
import { runCipherQuery } from "../../../api/api";
import { setFilterOpen, setRunQueryOpen, setQueryBuilderOpen, setOntologyOpen, setIsFromAddPredefinedQuery } from "../../../redux/reducers/sidebarReducer";
import SearchInput from "../../../components/searchInput/SearchInput";
import Favorites from "../../../components/favorites/Favorites";
import { extractNodesAndRelationshipsFromItemsAndFormat, deduplicateNodes } from "../../../helpers/neo4jFormat";
import Graph from "graphology";
import Settings from "../../../components/settings/settings";
import { Toaster } from "react-hot-toast";
import { CustomToast } from "../../../helpers/customToast";
import QueryBuilder from "../../../components/queryBuilder/QueryBuilder";
import { fabIcon, ontologyGraphData } from "../../../constants/screensData";
import CloseIcon from '@mui/icons-material/Close';
import OntologyModal from "../../../components/ontologyModal/OntologyModal";
import { setTableData } from "../../../redux/reducers/tableDataReducer";
import PredefinedQuery from "../../../components/predefinedQuery/PredefinedQuery"
import { initial } from "lodash";
import pdq_moved_notification from '../../../assets/images/PDQ_Moved.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { GraphVisualizer } from "../../../components/graphVisualization";

const GraphScreen = () => {
  const [nodes, setNodes] = useState<BasicNode[]>([]);
  const [relationships, setRelationships] = useState<BasicRelationship[]>([]);
  const dispatch = useAppDispatch()
  const [intialGraphReset, setIntialGraphReset] = useState<boolean>(false)
  const [queryRunning, setQueryRunning] = useState<boolean>(false)
  const [selectedFavQuery, setSelectedFavQuery] = useState<string>('')
  const [graphologyObject, setGraphologyObject] = useState<Graph>()
  const [autoCompleteRel, setAutoCompleteRel] = useState<boolean>(true)
  const [isFavoriteDisabled, setIsFavoriteDisabled] = useState<boolean>(true);

  const [showGraph, setShowGraph] = useState<boolean>(true);
  const [pdq_move_notification, setPdq_move_notification] = useState<boolean>(true);

  const highlightNodeRef = useRef();

  // get the store value using app selector 
  const isFilterOpen = useAppSelector((state) => state.sidebarReducer.isFilterOpen)
  const isFavoritesOpen = useAppSelector((state) => state.sidebarReducer.isFavoritesOpen)
  const isSettingsOpen = useAppSelector((state) => state.sidebarReducer.isSettingsOpen)
  const isQueryBuilderOpen = useAppSelector((state) => state.sidebarReducer.isQueryBuildersOpen)
  const intialNodes = useAppSelector((state) => state.graphReducer.graphNodes)
  const intialRelationships = useAppSelector((state) => state.graphReducer.graphRelationships)
  const isOntologyOpen = useAppSelector((state) => state.sidebarReducer.isOntologyOpen)
  const isPredefinedQueryOpen = useAppSelector((state) => state.sidebarReducer.isPredefinedQuery)
  const isFromAddPDQ = useAppSelector((state) => state.sidebarReducer.isFromAddPredefinedQuery)


  const [GraphGlobalSearch, setGraphGlobalSearch] = useState<string>('');
  const [filterNodeGlobalSearch, setFilterNodeGlobalSearch] = useState<BasicNode[]>([]);


  // Function to handle search input and filter nodes
  const handleSearchChange = (input: string) => {
    const query = input;
    setGraphGlobalSearch(query);
    const matchingNodes = nodes.filter((node) =>
      Object.values(node.properties).some((property) =>
        property.toString().toLowerCase().includes(query)
      )
    );
    console.log("71",matchingNodes)
    setFilterNodeGlobalSearch(matchingNodes)
  };
  


// ===========================================================================================
  // method to serialize the data and update store with intial data
  const intialGraph = async (apiNodes: any, apiRelations: any) => {
    if (apiNodes) {
      const { nodes: uniqNodes, nodeLimitHit } = deduplicateNodes(
        apiNodes,
        1000
      )
      const uniqRels = nodeLimitHit
        ? apiRelations.filter(
          rel =>
            !!uniqNodes.find(node => node.id === rel.startNodeId) &&
            !!uniqNodes.find(node => node.id === rel.endNodeId)
        )
        : apiRelations
      setNodes(uniqNodes)
      setRelationships(uniqRels)

      // update the store with nodes and relationships
      dispatch(setGraphNodes(apiNodes))
      dispatch(setGraphRelationships(apiRelations))
    }
  }



  // If all the filter nodes are deselected then reset the graph
  useEffect(() => {
    if (intialGraphReset) {
      setNodes(intialNodes)
      setRelationships(intialRelationships)
      setIntialGraphReset(false)
    }
    console.log("nodes",nodes)
  }, [intialGraphReset])


  const updateGraphStyle = (updatedGraphStyle: GraphStyleModel) => { }


  const onClickRun = async (cipherQuery: string) => {
    setQueryRunning(true)
    setShowGraph(false)
    setPdq_move_notification(false)
    const apiResult = await runCipherQuery(cipherQuery)
    if (!apiResult.graphData?.error) {
      if (apiResult.graphData && apiResult.graphData.length > 0) {
        const res = extractNodesAndRelationshipsFromItemsAndFormat(apiResult.graphData, 500)
        intialGraph(res?.nodes, res?.relationships)
        setGraphologyObject(res?.graphologyObject)
        // show the filters panel as default
        dispatch(setFilterOpen(true))
        setIsFavoriteDisabled(false)
        dispatch(setTableData(apiResult.tableData))
        !isFromAddPDQ && dispatch(setRunQueryOpen(false))

        CustomToast('Query ran successfully', {
          type: "success",
          style: {
            maxHeight: "300px",
            overflowY: "scroll",
          }
        })


      } else {
        // dispatch(setRunQueryOpen(true))
        CustomToast('No data found', {
          type: "error",
          style: {
            maxHeight: "300px",
            overflowY: "scroll",
          }
        })
      }
    } else {
      CustomToast(apiResult.graphData?.message, {
        type: "error",
        style: {
          maxHeight: "300px",
          overflowY: "scroll",
        }
      })
    }
    // make the run button loader to false and close the run modal
    setQueryRunning(false)
    // dispatch(setRunQueryOpen(false))
    dispatch(setQueryBuilderOpen(false))
    dispatch(setOntologyOpen(false))
    setShowGraph(true)



  }
  const handleOntologyOpen = () => {
    dispatch(setOntologyOpen(true))
  }

  const handleIsFavoriteDisabled = () => {
    setIsFavoriteDisabled(true)
  }  

  useEffect(() => {
    const hasSeenNotification = localStorage.getItem('hasSeenNotification');
    if (hasSeenNotification) {
      setPdq_move_notification(false);
    }
  }, []);
  // Automatically close the notification after 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setPdq_move_notification(false);
    }, 10000);

    return () => clearTimeout(timer); // Cleanup timer if component unmounts
  }, []);
  const closeNotification = () => {
    setPdq_move_notification(false);
    localStorage.setItem('hasSeenNotification', 'true');
  };

  const fabButtonStyle = {
    position: "absolute",
    right: "3rem",
    bottom: "2rem",
    backgroundColor: "#fff",
    zIndex: "2000",
    boxShadow: "2px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px -5px rgba(0,0,0,0.14), 2px 1px 18px 0px rgba(0,0,0,0.12)"
  }


  return (
    <>
      <Box position="absolute"
        top={80}
        left={isFavoritesOpen || isFilterOpen || isSettingsOpen || isPredefinedQueryOpen ? 400 : 0}
        zIndex={2}
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={"1rem"}
      >
        <SearchInput
          handleOnClickRun={onClickRun}
          queryRunning={queryRunning}
          setSelectedFavQuery={setSelectedFavQuery}
          onChangeQuery={handleIsFavoriteDisabled}
          selectedFavQuery={selectedFavQuery}
          handleSearchChange={handleSearchChange}
          nodes={nodes}
        />

        {pdq_move_notification &&
          <Box sx={{
            position: "relative",
            border: "1px solid #d9d9d9",
            borderRadius: '5px',
            padding: "0.5rem",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.18)"
          }}
          >
            <Box
              sx={{
                content: '""',
                position: "absolute",
                left: "-10px",
                top: "10px",
                width: 0,
                height: 0,
                borderTop: "10px solid transparent",
                borderBottom: "10px solid transparent",
                borderRight: "10px solid #d9d9d9" // Arrow color matching box border
              }}
            />
            <Typography fontSize={"0.8rem"}>
              Search bar now acts as a global search
            </Typography>
            <CloseIcon onClick={closeNotification} sx={{ color: "grey", cursor:"pointer" }} fontSize="small"/>
          </Box>
        }
      </Box>

      {pdq_move_notification &&


        <Box sx={{
          position: "absolute",
          top: "14.3rem",
          border: "1px solid #d9d9d9",
          borderRadius: '5px',
          padding: "0.5rem",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          zIndex:"1",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.18)"

        }}
        >
          <Box
            sx={{
              content: '""',
              position: "absolute",
              left: "-10px",
              top: "10px",
              width: 0,
              height: 0,
              borderTop: "10px solid transparent",
              borderBottom: "10px solid transparent",
              borderRight: "10px solid #d9d9d9" // Arrow color matching box border

            }}
          />
          <Typography fontSize={"0.8rem"}>
            Predefined Query has been moved to side panel
          </Typography>
          <CloseIcon onClick={closeNotification} sx={{ color: "grey", cursor:"pointer" }} fontSize="small" />
        </Box>
      }

      <Box mt={2} height="90vh" >
        {
          showGraph &&
          <ConnectedGraphVisualizer
            key={`${nodes.length}-${relationships.length}`}
            relationships={relationships}
            nodes={nodes}
            autocompleteRelationships={autoCompleteRel}
            isFullscreen={true}
            initialZoomToFit
            nodePropertiesExpandedByDefault={isFilterOpen}
            updateStyle={(undefined)}
            assignVisElement={() => undefined}
            getAutoCompleteCallback={() => undefined}
            setGraph={undefined}
            hasTruncatedFields={false}
            filterNodeGlobalSearch={filterNodeGlobalSearch}
            setNodePropertiesExpandedByDefault={() => undefined}
            wheelZoomInfoMessageEnabled={false}
            disableWheelZoomInfoMessage={() => undefined}
            useGeneratedDefaultColors={false}
            nodeLimitHit={false}
            graphologyObject={graphologyObject}
          />
        }
      </Box>

      {/* Modal for run query */}
      <RunQueryModal onSubmitRun={onClickRun} isFavoriteDisabled={isFavoriteDisabled} onChangeQuery={handleIsFavoriteDisabled} selectedFavQuery={selectedFavQuery} queryRunning={queryRunning} setSelectedFavQuery={setSelectedFavQuery} />
      <Favorites isFavoritesOpen={isFavoritesOpen} selectedFavQuery={selectedFavQuery} setSelectedFavQuery={setSelectedFavQuery} onChangeQuery={handleIsFavoriteDisabled} />
      <Settings isSettingsOpen={isSettingsOpen} />
      <QueryBuilder isQueryBuilderOpen={isQueryBuilderOpen} setSelectedFavQuery={setSelectedFavQuery} />
      <OntologyModal isOntologyOpen={isOntologyOpen} />
      <PredefinedQuery isPredefinedQueryOpen={isPredefinedQueryOpen} handleOnClickRun={onClickRun} queryRunning={queryRunning} setSelectedFavQuery={setSelectedFavQuery} />
      <Tooltip title="Ontology" placement="top-start" arrow>
        <Fab className="ten-step" size="medium" aria-label="Ontology" sx={fabButtonStyle} onClick={handleOntologyOpen} >
          <img src={fabIcon.ontologyIcon} />
        </Fab>
      </Tooltip>
      <Toaster />
    </>
  )
}

export default GraphScreen 