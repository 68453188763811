import { Box, Button, IconButton, Input, InputBase, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks/hook";
import { CustomToast } from "../../helpers/customToast";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Settings = ({ isSettingsOpen }: any) => {
    const dispatch = useAppDispatch();

    // Set initial state by checking localStorage
    const [maxNodes, setMaxNodes] = useState<number>(() => {
        const storedMaxNodes = localStorage.getItem("maxNodes");
        return storedMaxNodes ? Number(storedMaxNodes) : 1000;
    });

    // Local state for input value
    const [inputMaxNodes, setInputMaxNodes] = useState<number>(maxNodes);

    // Save maxNodes to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("maxNodes", String(maxNodes));
    }, [maxNodes]);

    const handleMaxNodes = () => {
        setMaxNodes(inputMaxNodes);
        CustomToast(`Maximum nodes to visualize set to ${inputMaxNodes}`, {
            type: "success",
            position: "top-center",
            duration: 2000,
        });
    };
    const handleReset = () => {
        setInputMaxNodes(1000); // Reset to default 1000
      };

      const handleIncrement = () => {
        setInputMaxNodes((prevValue) => Math.max(prevValue + 100, 0)); // Increment by 100, lower limit is 0
      };
      
      const handleDecrement = () => {
        setInputMaxNodes((prevValue) => Math.max(prevValue - 100, 0)); // Decrement by 100, lower limit is 0
      };

    return (
        <>
            {isSettingsOpen && (
                <Box sx={{
                    width: '25rem',
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    left: "4.5rem",
                    top: 60,
                    height: "calc(100vh - 60px)",
                    zIndex: 1000,
                }}>
                    <Box sx={{
                        borderBottom: "1px solid #E0E0E0",
                        paddingTop: "1.2rem",
                        paddingBottom: "1rem",
                    }}>
                        <Typography sx={{
                            textAlign: "center",
                            fontWeight: 600,
                            fontSize: "1.2rem",
                        }}>
                            Settings
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "1rem",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Typography>Max Node Limit:</Typography>

                        <Box display={"flex"}>
                            <Button variant="outlined" size="small" sx={{ minWidth: "1rem" }} onClick={handleIncrement}><AddIcon fontSize="small" /></Button>
                            <Input
                                sx={{ width: "4rem", background:"#f5f5f5" }}
                                value={inputMaxNodes}
                                disableUnderline={true}
                                placeholder="Max Node Limit"
                                inputProps={{
                                    sx: {
                                        textAlign: 'center',
                                         
                                    },
                                }}
                                onChange={(e) => setInputMaxNodes(Number(e.target.value))}
                            />
                            <Button variant="outlined" size="small" sx={{ minWidth: "1rem" }} onClick={handleDecrement}><RemoveIcon fontSize="small" /></Button>

                            {/* <TextField
                            id="outlined-basic"
                            type="number"
                            placeholder="Max Node Limit"
                            variant="standard"
                            size="small"
                            value={inputMaxNodes}
                            onChange={(e) => setInputMaxNodes(Number(e.target.value) + 100 - 1)}
                        /> */}

                        </Box>
                    </Box>
                    <Box display={'flex'} gap={'1vh'} justifyContent={'flex-end'} padding={"1rem"}>
                        <Button variant="outlined" sx={{textTransform:"none"}} onClick={handleReset}>Reset</Button>
                        <Button variant="contained" sx={{textTransform:"none"}} onClick={handleMaxNodes}>Apply</Button>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Settings;

