import React, { useRef } from "react";
import { Autocomplete, Box, Button, Card, CardContent, ClickAwayListener, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks/hook";
import { CustomToast } from "../../helpers/customToast";
import SearchIcon from '@mui/icons-material/Search';
import { addPredefinedQuery, deleteFavoriteByElementId, deletePredefinedQueryById, getPredefinedQueryByEmail, runCipherQuery, sharePredefinedQueryByEmail } from "../../api/api";
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from "@mui/lab";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import { Toaster } from "react-hot-toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { setIsFromAddPredefinedQuery, setPredefineQueryOpen, setRunQueryOpen } from "../../redux/reducers/sidebarReducer";

const PredefinedQuery = ({ isPredefinedQueryOpen, handleOnClickRun, queryRunning, setSelectedFavQuery }: any) => {
    const dispatch = useAppDispatch();
    const [searchInput, setSearchInput] = useState<string>('');
    const [new_PDQ, setNew_PDQ] = useState<any>(([]))
    const [selectedQueryItem, setSelectedQueryItem] = useState(null)
    const [isQueryModalOpen, setQueryModalOpen] = React.useState(false);
    const [queryParams, setQueryParams] = useState<any>([]);
    const [isAddPdqModalOpen, setisAddPdqModalOpen] = React.useState(false);
    const [propertyDataType, setpropertyDataType] = React.useState<any>([]);
    const [addPdqData, setaddPdqData] = React.useState<any>({});
    const [isSharePdqModalOpen, setisSharePdqModalOpen] = React.useState(false);
    const [user, setUser] = React.useState<any>([]);
    const [isDeletePDQModalOpen, setIsDeletePDQModalOpen] = useState(false);
    const [PDQ_ItemToDelete, setPDQ_ItemToDelete] = useState(null);

    const handleDeletePDQOpen = (item) => {
        setPDQ_ItemToDelete(item);  // Set the entire eachItem object
        setIsDeletePDQModalOpen(true);  // Open the modal
    };

    // Function to close the modal
    const handleDeletePDQClose = () => {
        setIsDeletePDQModalOpen(false);  // Close the modal
        setPDQ_ItemToDelete(null);  // Reset the selected item
    };


    const dbName = localStorage.getItem('dbName');


    const fetchPredefinedQuery = async () => {
        const pdq = await getPredefinedQueryByEmail()
        const filteredData = pdq?.filter(item => {
            const slicedViewName = item.viewName.length > 6 ? item.viewName.slice(0, -6) : item.viewName;
            return (dbName.startsWith(slicedViewName) || dbName === item.viewName);
        });
        
        setNew_PDQ(filteredData)

    }
    

    useEffect(() => {
        fetchPredefinedQuery();
    }, [isPredefinedQueryOpen])

    useEffect(() => {

        const getPropertyDataType = async () => {
            const nodeQuery = `
                CALL apoc.meta.nodeTypeProperties()
                YIELD propertyName, propertyTypes, nodeLabels
                WITH apoc.text.join(nodeLabels, '.') + '.' + propertyName AS entity_property,
                    reduce(s = '', pt IN propertyTypes | 
                            CASE WHEN s = '' THEN pt ELSE s + ', ' + pt END) AS property_types
                RETURN entity_property, property_types
            `
            const relQuery = `
                CALL apoc.meta.relTypeProperties()
                YIELD propertyName, propertyTypes, relType
                WITH relType + '.' + propertyName AS entity_property,
                    reduce(s = '', pt IN propertyTypes | 
                            CASE WHEN s = '' THEN pt ELSE s + ', ' + pt END) AS property_types
                RETURN entity_property, property_types
                ORDER BY entity_property
            `
            const nodeDataType = await runCipherQuery(nodeQuery)
            const relDataType = await runCipherQuery(relQuery)

            const propertyDataType = nodeDataType.tableData?.values.concat(relDataType.tableData?.values.filter(row => row.entity_property !== null && row.property_types !== null))
            setpropertyDataType(propertyDataType)
        }

        // getPredefined_Q();
        // fetchPredefinedQuery(); 
        getPropertyDataType();

    }, []);


    const handlePredefinedQueryClick = (item: any) => {
        setSelectedQueryItem(item)
        if (item?.parameter.length > 0) {

            //convertion array response into objest
            let params = item.parameter
            let param_type = item.parameter_type
            const paramsArray = params.map((label, index) => ({
                parameter: label,
                parameter_type: param_type[index]
            }));
            setQueryModalOpen(true)

            setQueryParams(paramsArray)
        } else {
            handleOnClickRun(item?.query)
        }
    };
    const handleShareQuery = (item: any) => {
        setSelectedQueryItem(item)
        setisSharePdqModalOpen(true)
    }

    const handleClose = () => {
        setQueryModalOpen(false)
    }
    const handleAddPdqClose = () => {
        setisAddPdqModalOpen(false)
    }
    const handleSharePdqClose = () => {
        setisSharePdqModalOpen(false)
    }

    useEffect(() => {
        if (!queryRunning) {
            setQueryModalOpen(false)
        }
    }, [queryRunning])

    const onClickRun = (paramsObject) => {
        const newQuery = constructNewQuery(paramsObject, selectedQueryItem.query)
        handleOnClickRun(newQuery)
        setSelectedFavQuery(newQuery)

    }


    const constructNewQuery = (params: any, query: string) => {
        let newQuery = query
        Object.keys(params).forEach((key) => {
            const { value, type } = params[key]
            newQuery = newQuery.replaceAll(
                `${key}`,
                `${type === "string" || type === "String" ? `'${value}'` : value}`
            );
        });
        return newQuery
    };

    const filteredPDQ = new_PDQ?.filter((item) =>
        item.description.toLowerCase().includes(searchInput.toLowerCase())

    );

    const handleDeleteQuery = async (elementId) => {
        // dispatch(setRunQueryOpen(false))
        try {
            const payload = {
                elementId: elementId
            }
            const result = await deletePredefinedQueryById(payload);
            if (result) {

                CustomToast("Predefined Qurey removed", {
                    type: "success",
                    position: "top-right",
                    duration: 2000,
                })
                fetchPredefinedQuery();
                handleDeletePDQClose();
                return
            } else {
                CustomToast("Error removing Predefined Qurey", {
                    type: "error",
                    position: "top-right",
                    duration: 2000
                })
            }
        } catch (err) {
            CustomToast("Error removing Predefined Qurey", {
                type: "error",
                position: "top-right",
                duration: 2000
            })
        }
        handleDeletePDQClose()
    }

    const deleteConfirm = () => {
        const deleteModalStyle = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "35rem",
            height: "auto",
            bgcolor: '#ffffff',
            borderRadius: "0.625rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
            p: "1rem",
            paddingBottom: "1.5rem"
        }
        return (
            <Modal
                open={isDeletePDQModalOpen}
                onClose={handleDeletePDQClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteModalStyle}>

                    <Box >
                        <Typography sx={{
                            fontSize: "1.25rem",
                            fontWeight: 600,
                            marginBottom: "1rem"
                        }}>
                            Delete Predefined Query?
                        </Typography>

                        <Typography color={"#333333"}>
                            This action will permanently delete the selected predefined query and cannot be undone.
                        </Typography>
                        <Typography color={"#333333"} marginTop={"1rem"}>
                            <b>Description:</b>
                            <br />
                            {PDQ_ItemToDelete?.description}
                        </Typography>

                        <Box display={"flex"} marginTop={"1rem"} gap={"1rem"} justifyContent={"flex-end"}>
                            <Button variant="outlined" sx={{ textTransform: "none" }} onClick={handleDeletePDQClose}>Cancel</Button>
                            <Button variant="contained" sx={{ textTransform: "none" }} color="error" onClick={() => handleDeleteQuery(PDQ_ItemToDelete?.elementId)}>Delete</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    return (
        <>
            {isPredefinedQueryOpen && (
                <Box sx={{
                    width: '25rem',
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    left: "4.5rem",
                    top: 60,
                    height: "calc(100vh - 60px)",
                    zIndex: 1000,
                }}>
                    <Box sx={{
                        borderBottom: "1px solid #E0E0E0",
                        paddingTop: "1.2rem",
                        paddingBottom: "1rem",
                    }}>
                        <Typography sx={{
                            textAlign: "center",
                            fontWeight: 600,
                            fontSize: "1.2rem",
                        }}>
                            Predefined Query
                        </Typography>
                    </Box>
                    <Box sx={{ padding: "1rem 1rem 0rem" }}>
                        <Button variant="contained"
                            startIcon={<AddIcon />} fullWidth disableElevation
                            sx={{ marginBottom: "2vh", textTransform: "none" }}
                            onClick={() => { dispatch(setRunQueryOpen(true)); dispatch(setIsFromAddPredefinedQuery(true)) }}>
                            Add Predefined Query
                        </Button>
                        <TextField
                            value={searchInput}
                            // type="search"
                            onChange={(e) => setSearchInput(e.target.value)}
                            placeholder="Search by description..."
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                sx: {
                                    color: '#808080',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '7px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        <Typography sx={{
                            marginTop: ".5rem",
                            fontSize: "0.9rem",
                            color: "#808080",
                            // marginBottom: ".5rem",
                            padding:"1vh"
                        }}>
                            Listed predefined query are specific to the <b style={{ color: "#461e96" }}>View: {dbName}</b>
                        </Typography>
                    </Box>
                    <Box width="100%" sx={{ overflow: "scroll", height: "60vh", padding: "1rem 1rem 0rem" }}>
                        {
                            filteredPDQ && filteredPDQ?.map((eachItem, index) => (
                                <Card elevation={0} sx={{ border: "1px solid #E0E0E0", marginBottom: "1rem" }} key={index} >
                                    <CardContent sx={{ cursor: "pointer", display: "flex", justifyContent: "space-between", paddingBottom: "16px !important" }}>
                                        <Typography sx={{
                                            marginBottom: "1rem",
                                            cursor: "pointer"
                                        }} key={index}
                                            onClick={() => handlePredefinedQueryClick(eachItem)}
                                        >
                                            <Typography sx={{
                                                fontSize: "0.875rem",
                                                color: "#333333",
                                                fontWeight: 500,
                                                // marginBottom: "1rem",
                                                cursor: "pointer"
                                            }}
                                                dangerouslySetInnerHTML={{ __html: eachItem?.description }}>

                                            </Typography>
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

                                            {
                                                eachItem.visibility == "private" && (
                                                    <>
                                                        <IconButton onClick={() => handleShareQuery(eachItem)}>
                                                            <ShareIcon fontSize="small" sx={{ color: "#461e96" }} />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleDeletePDQOpen(eachItem)}>
                                                            <DeleteOutlineIcon fontSize="small" sx={{ color: "red" }} />
                                                        </IconButton>
                                                        {deleteConfirm()}
                                                    </>

                                                )
                                            }

                                        </Box>

                                    </CardContent>
                                </Card>
                            ))
                        }
                        {
                            filteredPDQ && filteredPDQ.length == 0 &&
                            <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>No Predefined Query found</Typography>
                        }
                    </Box>
                </Box>
            )}
            <QueryInputModal isQueryModalOpen={isQueryModalOpen} handleClose={handleClose} paramsArray={queryParams} queryRunning={queryRunning} onClickRun={onClickRun} />
            {/* <AddPredefinedQueryModal isAddPdqModalOpen={isAddPdqModalOpen} handleAddPdqClose={handleAddPdqClose} propertyDataType={propertyDataType} PdqCypherQuery/> */}
            <SharePredefinedQueryModal isSharePdqModalOpen={isSharePdqModalOpen} handleSharePdqClose={handleSharePdqClose} selectedQueryItem={selectedQueryItem} user={user} />
        </>
    );
};

export default PredefinedQuery;


const inputModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30rem",
    height: "auto",
    bgcolor: '#ffffff',
    borderRadius: "0.625rem",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
    p: "1rem",
};

const QueryInputModal = ({ isQueryModalOpen, handleClose, paramsArray, onClickRun, queryRunning }: any) => {
    const [finalParams, setFinalParams] = useState<any>({})

    const handleInputParamChange = (param, e) => {
        setFinalParams({
            ...finalParams, [param.parameter]: {
                value: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
                type: param?.parameter_type
            }

        })
    }


    return (
        <Modal
            open={isQueryModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={inputModalstyle}>
                {
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography sx={{
                            color: "#333333",
                            fontSize: "1.25rem",
                            fontWeight: 500,
                            marginBottom: "1.25rem"
                        }}>
                            Enter the parameters
                        </Typography>
                        {
                            paramsArray.map((eachParam, index) => (
                                <Box key={index} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "100%",
                                }}>
                                    <TextField
                                        id="outlined-basic"
                                        label={eachParam.parameter}
                                        variant="outlined"
                                        sx={{
                                            width: "80%",
                                            marginBottom: "1rem"
                                        }}
                                        onChange={(e) => handleInputParamChange(eachParam, e)}
                                    />
                                    <Typography sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: 700,
                                        marginBottom: "1rem",
                                        marginLeft: "1rem",
                                        width: "20%",
                                        textAlign: "center",
                                        border: "1px solid #d9d9d9",
                                        borderRadius: "0.625rem",
                                        padding: "0.25rem",
                                        backgroundColor: "#F2F2F2",
                                    }}>
                                        {eachParam?.parameter_type}

                                    </Typography>
                                </Box>
                            ))
                        }

                        <LoadingButton sx={{
                            backgroundColor: "#461E96",
                            color: "white",
                            width: "50%",
                            height: "3rem",
                            fontSize: "1rem",
                            fontWeight: 600,
                            textTransform: "none"
                        }}
                            onClick={() => onClickRun(finalParams)}
                            variant="contained"
                            loading={queryRunning}
                        >Run</LoadingButton>
                    </Box>
                }
            </Box>
        </Modal>
    )
}

const addPdqModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50rem",
    maxHeight: "45rem",
    overflow: "auto",
    bgcolor: '#ffffff',
    borderRadius: "0.625rem",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
    p: "1rem",
};

export const AddPredefinedQueryModal = ({ isAddPdqModalOpen, setisAddPdqModalOpen, handleAddPdqClose, propertyDataType, PdqCypherQuery }) => {
    const dbName = localStorage.getItem('dbName');
    const dispatch = useAppDispatch();

    const [description, setDescription] = useState('');
    const [parameters, setParameters] = useState([]);
    const [parameterTypes, setParameterTypes] = useState([]);
    const [cypherQuery, setcypherQuery] = useState(PdqCypherQuery);
    const [nullCheck, setnullCheck] = useState<boolean>(false);

    const [desc_anchorEl, setdesc_AnchorEl] = React.useState<HTMLElement | null>(null);
    const [params_anchorEl, setparams_AnchorEl] = React.useState<HTMLElement | null>(null);
    const [query_anchorEl, setquery_AnchorEl] = React.useState<HTMLElement | null>(null);

    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (param, index) => {
        navigator.clipboard.writeText(param).then(() => {
            setCopiedIndex(index);
            setTimeout(() => setCopiedIndex(null), 2000); // Reset after 2 seconds

        });
    };

    useEffect(() => {
        setcypherQuery(PdqCypherQuery);
    }, [PdqCypherQuery]);


    const desc_PopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setdesc_AnchorEl(event.currentTarget);
    };
    const param_PopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setparams_AnchorEl(event.currentTarget);
    };
    const query_PopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setquery_AnchorEl(event.currentTarget);
    };

    const desc_PopoverClose = () => {
        setdesc_AnchorEl(null);
    };
    const params_PopoverClose = () => {
        setparams_AnchorEl(null);
    };
    const query_PopoverClose = () => {
        setquery_AnchorEl(null);
    };

    const desc_open = Boolean(desc_anchorEl);
    const params_open = Boolean(params_anchorEl);
    const query_open = Boolean(query_anchorEl);


    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setDescription(value);

        // Capture parameters between `$`
        const paramMatches = value.match(/\$(\w+)/g);
        const updatedParameters = paramMatches ? paramMatches.map((param) => `Enter_${param.slice(1)}`) : [];

        // Update parameters and parameter types
        setParameters(updatedParameters);
        setParameterTypes(new Array(updatedParameters.length).fill('')); // Empty string for each parameter type initially
    };

    const handleChangeProperty = (index, value) => {
        const newParameterTypes = [...parameterTypes];
        newParameterTypes[index] = value ? value.property_types : '';
        setParameterTypes(newParameterTypes);
    };
    const handleSubmit = async () => {

        const data = {
            description,
            parameters,
            parameterTypes,
            cypherQuery,
            dbName
        }

        const nullChecks = Object.keys(data).filter(key => !data[key] || (Array.isArray(data[key]) && data[key].length === 0));
        if (nullChecks.length > 0) {
            setnullCheck(true)
            setTimeout(() => {
                setnullCheck(false);
            }, 5000);
        }
        else {
            setnullCheck(false)
            try {
                const payload = {
                    description: description,
                    parameter: parameters,
                    parameter_type: parameterTypes,
                    query: cypherQuery,
                    viewName: dbName,
                    visibility: 'private'
                }
                const newPDQ = await addPredefinedQuery(payload)
                if (newPDQ) {
                    CustomToast("Predefined Query has been added", {
                        type: "success",
                        position: "top-right",
                        duration: 2000
                    })
                }
            } catch (err) {
                CustomToast("Failed to add the predefined query", {
                    type: "error",
                    position: "top-right",
                    duration: 2000
                })
            }
        }
        dispatch(setRunQueryOpen(false))
        dispatch(setPredefineQueryOpen(false))
        setisAddPdqModalOpen(false)
        handleReset()

    }

    const handleReset = () => {
        setDescription('')
        setParameters([])
        setParameterTypes([])
        setcypherQuery('')
        setnullCheck(false)
    }
    interface Parameter {
        entity_property: string;
        property_types: string;
    }


    return (
        <Modal
            open={isAddPdqModalOpen}
            onClose={handleAddPdqClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={addPdqModalstyle}>
                {
                    <Box display="flex" flexDirection="column" >
                        <Box display="flex" mb={1} flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={"1rem"}>
                            <Typography sx={{
                                fontSize: "1.25rem",
                                fontWeight: 600,
                            }}>
                                Add Predefined Query
                            </Typography>
                            <Typography sx={{
                                textAlign: "right",
                            }}>
                                <CloseOutlinedIcon onClick={handleAddPdqClose} sx={{
                                    fontSize: "1.3rem",
                                    fontWeight: 600,
                                    cursor: "pointer"
                                }} />
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                            <Box sx={{ flexGrow: 1, marginRight: "0.5rem" }}>
                                <Typography sx={{ textAlign: "left", marginBottom: "0.9rem", }}>Enter query description with parameters</Typography>
                                <TextField
                                    id="outlined-basic"
                                    label="Description"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </Box>
                            <Box>
                                <IconButton onMouseEnter={desc_PopoverOpen} onMouseLeave={desc_PopoverClose}>
                                    <InfoIcon />
                                </IconButton>

                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={desc_open}
                                    anchorEl={desc_anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    onClose={desc_PopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography sx={{ p: 1, paddingBottom: "0", fontSize: "0.9rem", fontWeight: "bold" }}>Please ensure to add a $ dollar symbol before each parameters</Typography>
                                    <Typography sx={{ p: 1, fontSize: "0.9rem" }}><b>Sample:</b><br /> Customer located in $City</Typography>
                                </Popover>
                            </Box>
                        </Box>
                        <br />
                        <Box sx={{ display: "flex", justifyContent: "space-between", flexGrow: 1 }}>
                            <Box >
                                <Typography sx={{ textAlign: "left", marginBottom: "0.9rem", }}>Parameters</Typography>
                                {parameters?.length > 0 && (
                                    <>
                                        {parameters.map((param, index) => (
                                            <>
                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography key={index} color={"#747474"} marginBottom={"2rem"}>{param}</Typography>
                                                    {/* <ContentCopyIcon fontSize="small" sx={{ cursor: "pointer", marginLeft: "0.7rem" }}
                                                        onClick={() => navigator.clipboard.writeText(param)} /> */}
                                                    <Tooltip
                                                        title="✓ Copied"
                                                        open={copiedIndex === index}
                                                        disableHoverListener
                                                        disableFocusListener
                                                        disableTouchListener
                                                        arrow
                                                        placement="right"
                                                        sx={{
                                                            fontSize: "0.5rem !important",
                                                            padding: "0",
                                                        }}

                                                    >
                                                        <ContentCopyIcon
                                                            fontSize="small"
                                                            sx={{ cursor: "pointer", marginLeft: "0.7rem" }}
                                                            onClick={() => handleCopy(param, index)}
                                                        />
                                                    </Tooltip>
                                                </Box>

                                            </>
                                        ))}
                                    </>
                                )}
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                <Box marginRight={"0.5rem"}>
                                    <Typography sx={{ textAlign: "left", marginBottom: "0.7rem", }}>Select the property name of parameters</Typography>
                                    {parameters.length > 0 && parameters?.map((param, index) => (
                                        <FormControl key={index} size="small" fullWidth sx={{ marginBottom: '1rem' }}>
                                            <Autocomplete
                                                id={`autocomplete-property-${index}`}
                                                size="small"
                                                options={propertyDataType}
                                                getOptionLabel={(option: Parameter) => option.entity_property || ""}
                                                onChange={(event, value) => handleChangeProperty(index, value)}
                                                renderInput={params => (
                                                    <TextField {...params} label='Label_Property' variant='outlined' />
                                                )}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: 200,
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    ))}
                                </Box>
                                <IconButton onMouseEnter={param_PopoverOpen} onMouseLeave={params_PopoverClose}>
                                    <InfoIcon />
                                </IconButton>
                            </Box>

                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: 'none',
                                }}
                                open={params_open}
                                anchorEl={params_anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={params_PopoverClose}
                                disableRestoreFocus
                            >
                                <Typography sx={{ p: 2, fontSize: "0.9rem", fontWeight: "bold" }}>Select the Label and Property the parameter is referring to</Typography>
                            </Popover>
                        </Box>
                        { 
                            parameters.length == 0 && (
                                <Typography sx={{ textAlign: "left", marginBottom: "0.9rem", color: "#747474" }}>
                                    No Parameters Found, Please add the description
                                </Typography>
                            )
                        }
                        <br />
                        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                            <Box sx={{ flexGrow: 1, marginRight: "0.5rem" }}>
                                <Typography sx={{ marginBottom: "0.9rem" }}>Enter Cypher Query</Typography>
                                <textarea rows={15} style={{
                                    width: "100%",
                                    borderRadius: "0.6rem",
                                    backgroundColor: "#F5F5F5",
                                    border: "0",
                                    padding: "0.5rem",
                                    resize: "none",
                                    marginBottom: "0.9rem"
                                }}
                                    onChange={(e) => { setcypherQuery(e.target.value) }}
                                    value={cypherQuery}
                                ></textarea>
                            </Box>
                            <Box>
                                <IconButton onMouseEnter={query_PopoverOpen} onMouseLeave={query_PopoverClose}>
                                    <InfoIcon />
                                </IconButton>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={query_open}
                                    anchorEl={query_anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    onClose={query_PopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography sx={{ p: 1, paddingBottom: '0', fontSize: "0.9rem", fontWeight: "bold" }}>Make sure the parameters mentioned in the description are included in the query.<br />Note that parameters are case sensitive.</Typography>

                                    <Typography sx={{ p: 1, fontSize: "0.9rem", }}><b>Sample:</b> <br />  MATCH p = (n:customer)-[:LOCATED_IN_CITY]-{'>'}(c:city)<br />WHERE c.city = City_Params<br />RETURN p</Typography>
                                </Popover>
                            </Box>
                        </Box>

                        <Typography sx={{ marginBottom: "0.9rem", }}>View Name</Typography>
                        <Typography sx={{ color: "#747474" }}>{dbName}</Typography>

                        <Box sx={{ display: "flex", marginTop: "1rem", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                {nullCheck && <Typography color={"red"}>Please fill all the field.</Typography>}
                            </Box>
                            <Box>
                                <Button variant="outlined" sx={{ textTransform: "none", width: "8rem", marginRight: "2vh" }} onClick={handleReset}>Reset</Button>
                                <Button variant="contained" sx={{ textTransform: "none", width: "8rem", }} onClick={handleSubmit}>Add</Button>
                            </Box>
                        </Box>

                    </Box>
                }
            </Box>
        </Modal>
    )
}

const SharePredefinedQueryModal = ({ isSharePdqModalOpen, handleSharePdqClose, selectedQueryItem, user }) => {
    const addPdqModalstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50rem",
        bgcolor: '#ffffff',
        borderRadius: "0.625rem",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
        p: "1rem",
    };

    const [selectedUser, setSelectedUser] = useState<any>([])
    const [User, setUser] = useState<any>([])

    useEffect(() => {
        const getUser = async () => {
            const userQuery = `use graphexploreroptions MATCH (n:User) RETURN n`
            const user = await runCipherQuery(userQuery)
            setUser(user.graphData?.map(item => item.properties.email))

        }
        getUser();
    }, [isSharePdqModalOpen])

    const handleSubmit = async () => {

        try {
            const payload = {
                description: selectedQueryItem.description,
                selectedUser: selectedUser
            }
            const newFavorite = await sharePredefinedQueryByEmail(payload)
            if (newFavorite) {
                CustomToast("Query has been shared", {
                    type: "success",
                    position: "top-right",
                    duration: 2000
                })
                handleSharePdqClose();
            }
            else {
                CustomToast("Failed to share the query", {
                    type: "error",
                    position: "top-right",
                    duration: 2000
                })
            }
        } catch (err) {
            CustomToast("Failed to share the query", {
                type: "error",
                position: "top-right",
                duration: 2000
            })
        }

    }

    const handleReset = () => {
        setUser([""])
        setSelectedUser([""])
    }

    return (
        <>
            <Modal
                open={isSharePdqModalOpen}
                onClose={handleSharePdqClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={addPdqModalstyle}>
                    {
                        <Box display="flex" flexDirection="column" >
                            <Box display="flex" mb={1} flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={"1rem"}>
                                <Typography sx={{
                                    fontSize: "1.25rem",
                                    fontWeight: 600,
                                }}>
                                    Share Predefined Query
                                </Typography>
                                <Typography sx={{
                                    textAlign: "right",
                                }}>
                                    <CloseOutlinedIcon onClick={handleSharePdqClose} sx={{
                                        fontSize: "1.3rem",
                                        fontWeight: 600,
                                        cursor: "pointer"
                                    }} />
                                </Typography>
                            </Box>
                            <Typography sx={{ textAlign: "left", marginBottom: "0.7rem", }}>Select the recipent's Email to share the query</Typography>
                            <FormControl size="small" fullWidth sx={{ marginBottom: '1rem' }}>

                                <Autocomplete
                                    id="email-autocomplete"
                                    multiple
                                    options={User}
                                    defaultValue={[]}
                                    size="small"
                                    onChange={(event, newValue) => setSelectedUser(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Email"
                                            variant="outlined"
                                        />
                                    )}
                                    ListboxProps={{
                                        style: {
                                            maxHeight: 200,
                                        },
                                    }}
                                />
                            </FormControl>
                            <Typography sx={{ textAlign: "left", marginBottom: "0.9rem", }}>Description</Typography>
                            <Typography sx={{
                                textAlign: "left",
                                marginBottom: "0.9rem",
                                color: "#747474",
                                background: "#f5f5f5",
                                padding: "1rem",
                                borderRadius: "5px"
                            }}>
                                {selectedQueryItem && selectedQueryItem.description}
                            </Typography>
                            <Typography sx={{ textAlign: "left", marginBottom: "0.9rem", }}>Cypher Query</Typography>
                            <Typography sx={{
                                textAlign: "left",
                                marginBottom: "0.9rem",
                                color: "#747474",
                                maxHeight: "20rem",
                                overflow: "scroll",
                                background: "#f5f5f5",
                                padding: "1rem",
                                borderRadius: "5px"
                            }}>
                                {selectedQueryItem && selectedQueryItem.query}
                            </Typography>

                            <Box sx={{ display: "flex", gap: "2vh", marginTop: "1rem", justifyContent: "flex-end" }}>
                                <Button variant="outlined" sx={{ textTransform: "none", width: "15%", }} onClick={handleReset}>Reset</Button>
                                <Button variant="contained" sx={{ textTransform: "none", width: "15%", }} onClick={handleSubmit}>Share</Button>
                            </Box>

                        </Box>
                    }
                </Box>
            </Modal>

        </>
    )
}


