import React, { useEffect, useState } from 'react'
import { setOntologyOpen } from '../../redux/reducers/sidebarReducer'
import { useAppDispatch } from '../../redux/hooks/hook'
import { Box, Button, IconButton, Modal, Switch, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { ontologyGraphData } from '../../constants/screensData';
import {
    TransformWrapper,
    TransformComponent,
    useControls
} from "react-zoom-pan-pinch";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DownloadIcon from '@mui/icons-material/Download';
import FitScreenOutlinedIcon from '@mui/icons-material/FitScreenOutlined';
import ontologyWithPlaceholder from '../../assets/images/marketAcess_ontology_placeholder.svg'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
    height: "90%",
    width: "85%"
};


export default function OntologyModal({ isOntologyOpen }) {
    const dispatch = useAppDispatch();
    const dbName = localStorage.getItem('dbName');

    const [filteredOntology, setfilteredOntology] = useState<any>(ontologyGraphData);
    const [isWithPlaceholder, setIsWithPlaceholder] = React.useState(false);

    const handleChange = (event) => {
        setIsWithPlaceholder(event.target.checked);
    };

    useEffect(() => {
        const dbName = localStorage.getItem('dbName');
        if (dbName) {
            const filteredData = filteredOntology.filter(item => dbName.includes(item.viewName));
            setfilteredOntology(filteredData);
        }
    }, []);

    const handleClose = () => {
        dispatch(setOntologyOpen(false));
    };

    const Controls = ({ imgSrc }) => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        const downloadImage = () => {
            const link = document.createElement('a');
            link.href = imgSrc;
            link.download = 'ontology_image.svg'; // You can set the name of the downloaded file here
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        return (
            <>
                <Box sx={{ position: "absolute", top: "0rem", right: "4rem", zIndex: "1", backgroundColor: "#f3eeff", borderRadius: "6vh", }}>
                    <IconButton sx={{ color: '#461e96' }} onClick={() => zoomIn()}><ZoomInIcon /></IconButton>
                    <IconButton sx={{ color: '#461e96' }} onClick={() => zoomOut()} ><ZoomOutIcon /></IconButton>
                    <IconButton sx={{ color: '#461e96' }} onClick={() => resetTransform()} ><FitScreenOutlinedIcon /></IconButton>
                    <IconButton sx={{ color: '#461e96' }} onClick={downloadImage}><DownloadIcon /></IconButton>
                </Box>

            </>
        );
    };
    return (
        <Modal
            open={isOntologyOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    sx={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        color: "#461e96",
                        zIndex: '1'
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Typography
                    fontWeight={"600"}
                    zIndex={"1"}
                    position={"absolute"}
                    fontSize={"1.2rem"}
                    margin={"0.5vh"}
                >
                    Ontology | {dbName}
                </Typography>
                {

                    dbName == 'marketaccess' &&
                    <Box sx={{
                        position: "absolute",
                        left: "56%",
                        zIndex: "100",
                        background: "#fff"
                    }}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Switch
                                checked={isWithPlaceholder}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography>Ontology With Placeholder</Typography>
                        </Box>
                    </Box>
                }
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        position: 'relative'
                    }}
                >
                    {filteredOntology.map((ontology, index) => (
                        <Box key={index} sx={{ maxWidth: '100%', maxHeight: '100%' }}>
                            <TransformWrapper>
                                {isWithPlaceholder ? <Controls imgSrc={ontologyWithPlaceholder} /> : <Controls imgSrc={ontology?.ontologyImg} />}
                                <TransformComponent>
                                    {
                                        isWithPlaceholder ?
                                            <img
                                                src={ontologyWithPlaceholder}
                                                style={{
                                                    maxHeight: '90%',
                                                    maxWidth: '80%',
                                                    display: 'block',
                                                    margin: 'auto'
                                                }}
                                                alt="ontology"
                                            />
                                            :
                                            <img
                                                src={ontology?.ontologyImg}
                                                style={{
                                                    maxHeight: '90%',
                                                    maxWidth: '80%',
                                                    display: 'block',
                                                    margin: 'auto'
                                                }}
                                                alt="ontology"
                                            />
                                    }

                                </TransformComponent>
                            </TransformWrapper>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Modal>

    );
}
