import { Box, Button, Card, CardContent, Chip, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { deleteFavoriteByElementId, getFavoritesByEmail } from "../../api/api"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useAppDispatch } from "../../redux/hooks/hook";
import { setFavoritesOpen, setIsFromAddPredefinedQuery, setRunQueryOpen } from "../../redux/reducers/sidebarReducer";
import { Toaster } from "react-hot-toast";
import { CustomToast } from "../../helpers/customToast";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

interface userFavorites {
    query: string
    description: string
    viewName: string

}

const Favorites = ({ isFavoritesOpen,selectedFavQuery, setSelectedFavQuery, onChangeQuery }: any) => {
    const dispatch = useAppDispatch()
    const [userFavorites, setUserFavorites] = useState<userFavorites[]>([])
    const [favLoading, setFavLoading] = useState<boolean>(false)
    const dbName = localStorage.getItem("dbName");
    const [searchInput, setSearchInput] = useState<string>('');
    const [isDeleteFavModalOpen, setisDeleteFavModalOpen] = useState(false);
    const [Fav_ItemToDelete, setFav_ItemToDelete] = useState(null);

    const handleDeletePDQOpen = (item) => {
        setFav_ItemToDelete(item);  // Set the entire eachItem object
        setisDeleteFavModalOpen(true);  // Open the modal
    };

    // Function to close the modal
    const handleDeletePDQClose = () => {
        setisDeleteFavModalOpen(false);  // Close the modal
        setFav_ItemToDelete(null);  // Reset the selected item
    };

    useEffect(() => {
        if (isFavoritesOpen) {
            fetchFavorites()
        }
    }, [isFavoritesOpen])

    // useEffect(() => {
    //     if (selectedFavQuery) {
    //         setCipherQuery(selectedFavQuery)
    //     }
    // }, [selectedFavQuery])

    const fetchFavorites = async () => {
        setFavLoading(true)
        const favoritesResult = await getFavoritesByEmail()
        if (favoritesResult) {
            setUserFavorites(favoritesResult)
        }
        else {
            setUserFavorites([])
        }
        setFavLoading(false)
    }

    const onClickEachFavorite = (query: string) => {
        // setSelectedFavQuery('')
        if (query) {

            setSelectedFavQuery(query)
            onChangeQuery()
            dispatch(setRunQueryOpen(true))
            dispatch(setFavoritesOpen(false))
            dispatch(setIsFromAddPredefinedQuery(false))
        }
    }

    const onClickDeleteFavorite = async (elementId: string) => {
        dispatch(setRunQueryOpen(false))
        try {
            const result = await deleteFavoriteByElementId(elementId);
            if (result) {
                setisDeleteFavModalOpen(false)
                CustomToast("Favorite removed", { 
                    type: "success",
                    position: "top-right",
                    duration: 2000,
                })
                fetchFavorites()
                return
            } else {
                CustomToast("Error removing favorite", {
                    type: "error",
                    position: "top-right",
                    duration: 2000
                })
            }
        } catch (err) {
            setisDeleteFavModalOpen(true)
            CustomToast("Error removing favorite", {
                type: "error",
                position: "top-right",
                duration: 2000
            })
        }
    }
    let modifiedDbName = dbName && dbName.startsWith('srmsupplyside') ? dbName.slice(0, -6) : dbName;
    const filteredFavorites = userFavorites.filter(
        (eachFavorite) =>
            eachFavorite.viewName === modifiedDbName &&
            eachFavorite.description.toLowerCase().includes(searchInput.toLowerCase())
    );

    const deleteConfirm = () => {
        const deleteModalStyle = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "35rem",
            height: "auto",
            bgcolor: '#ffffff',
            borderRadius: "0.625rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
            p: "1rem",
            paddingBottom: "1.5rem"
        }
        return (
            <Modal
                open={isDeleteFavModalOpen}
                onClose={handleDeletePDQClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteModalStyle}>

                    <Box >
                        <Typography sx={{
                            fontSize: "1.25rem",
                            fontWeight: 600,
                            marginBottom: "1rem"
                        }}>
                            Delete Favorites?
                        </Typography>

                        <Typography color={"#333333"}>
                            This action will permanently delete the selected favorites and cannot be undone.
                        </Typography>
                        <Typography color={"#333333"} marginTop={"1rem"}>
                            <b>Description:</b>
                            <br />
                            {Fav_ItemToDelete?.description}
                        </Typography>

                        <Box display={"flex"} marginTop={"1rem"} gap={"1rem"} justifyContent={"flex-end"}>
                            <Button variant="outlined" sx={{ textTransform: "none" }} onClick={handleDeletePDQClose}>Cancel</Button>
                            <Button variant="contained" sx={{ textTransform: "none" }} color="error" onClick={() => onClickDeleteFavorite(Fav_ItemToDelete?.elementId)}>Delete</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    return (
        <>
            {isFavoritesOpen && (
                <Box sx={{
                    width: '25rem',
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    left: "4.5rem",
                    top: 60,
                    height: "calc(100vh - 60px)",
                    zIndex: 1000,
                    overflowY: "auto"
                }}>
                    <Box sx={{
                        borderBottom: "1px solid #E0E0E0",
                        paddingTop: "1.2rem",
                        paddingBottom: "1rem",
                    }}>
                        <Typography sx={{
                            textAlign: "center",
                            fontWeight: 600,
                            fontSize: "1.2rem",
                        }}>
                            Favorites
                        </Typography>
                    </Box>
                    <Box sx={{ padding: "1rem 1rem 0rem" }}>
                    <Button variant="contained"
                            startIcon={<AddIcon />} fullWidth disableElevation
                            sx={{ marginBottom: "2vh", textTransform: "none" }}
                            onClick={() => { dispatch(setRunQueryOpen(true)); dispatch(setIsFromAddPredefinedQuery(true)) }}>
                            Add a New Favorite
                        </Button>
                        <TextField
                            value={searchInput}
                            // type="search"
                            onChange={(e) => setSearchInput(e.target.value)}
                            placeholder="Search by description..."
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                sx: {
                                    color: '#808080',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '7px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />

                        <Typography sx={{
                            marginTop: ".5rem",
                            fontSize: "0.9rem",
                            color: "#808080",
                            padding:"1vh"
                        }}>
                            {/* The favorites listed below are specific to the <br/> <b style={{ color: "#461e96" }}>View: {dbName}</b> */}
                            Listed favorites are specific to the <b style={{ color: "#461e96" }}>View: {dbName}</b>

                        </Typography>
                    </Box>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "1rem",
                        gap: "1rem",
                    }}>
                        {
                            favLoading ? (
                                <Typography align="center">Loading Favorites.....</Typography>
                            ) : (
                                <>
                                    {
                                        filteredFavorites && filteredFavorites.filter((eachFavorite) => eachFavorite.viewName === modifiedDbName)
                                            .map((eachFavorite: any, index) => (
                                                <Card elevation={0} sx={{ border: "1px solid #E0E0E0", }} key={index} >
                                                    <CardContent sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 0 }}
                                                    >

                                                        <Box sx={{ width: "100%" }} >
                                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                <Typography sx={{
                                                                    fontWeight: 500,
                                                                    fontSize: "1rem",
                                                                    color: "#333333",
                                                                    cursor: "pointer",
                                                                }}
                                                                    onClick={() => onClickEachFavorite(eachFavorite?.query)}
                                                                >
                                                                    {eachFavorite?.description}
                                                                </Typography>
                                                                <IconButton onClick={() => handleDeletePDQOpen(eachFavorite)} sx={{ zIndex: "100" }}>
                                                                    <DeleteOutlineOutlinedIcon
                                                                        sx={{
                                                                            color: "red",
                                                                            cursor: "pointer",
                                                                        }} />
                                                                </IconButton>
                                                                {deleteConfirm()}

                                                            </Box>

                                                            <Typography sx={{
                                                                fontFamily: "AbGilroy Medium",
                                                                fontWeight: 500,
                                                                fontSize: "0.875rem",
                                                                color: "#333333",
                                                                cursor:"pointer"
                                                            }}
                                                                onClick={() => onClickEachFavorite(eachFavorite?.query)}
                                                            >
                                                                {eachFavorite?.query.split('').slice(0, 100).join('')}...
                                                            </Typography>
                                                            {/* <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "1vh" }}>
                                                                <Chip label={eachFavorite?.viewName} size="small" variant="outlined" />
                                                            </Box> */}
                                                        </Box>

                                                    </CardContent>
                                                </Card>

                                            ))
                                    }
                                    {filteredFavorites && filteredFavorites.length == 0 &&
                                        <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>No favorites found</Typography>}
                                </>
                            )
                        }
                    </Box>
                </Box>
            )}
            <Toaster />
        </>
    )
}

export default Favorites