import { Box, Button, Chip, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GetStartesModal from '../../../components/getStartedModal/getStartedModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/hook';
import { setGetStartedModal } from '../../../redux/reducers/getStartedModalReducer';
import { runCipherQuery } from '../../../api/api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ViewData {
    name: string;
    dbName: string;
    subName: string;
    isTemporalData: boolean;
}

interface viewCountData {
    nodeCount: number;
    labelCount: number;
    relationshipCount: number;
    relationshipTypeCount: number;
    labels?: string[];
    dbList?: string[];
}

const NumberToMonthNames = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
};
const monthNameToNumber = {
    'January': '01',
    'February': '02',
    'March': '03',
    'April': '04',
    'May': '05',
    'June': '06',
    'July': '07',
    'August': '08',
    'September': '09',
    'October': '10',
    'November': '11',
    'December': '12'
};

const ViewsData: ViewData[] = [
    {
        name: "Supply Side",
        dbName: 'srmsupplyside',
        subName: "Contains USP data, HIC tables, Vendor & material master and UNSD locations data",
        isTemporalData: true,
    },
    {
        name: "Sell Side",
        dbName: 'srmsellside',
        subName: "Contains Finops table, shipment, hub and customer data",
        isTemporalData: false,
    },
    {
        name: "Customer 360 & Segmentation",
        dbName: 'kgmvp',
        subName: "Craft and implement use case scenarios for Customer 360 and segmentation to facilitate Product Segmentation and Product Availability use cases, while also commencing the implementation of the Semantic Layer MVP in accordance with the strategic roadmap.",
        isTemporalData: false,
    },
    {
        name: "Market Access",
        dbName: 'marketaccess',
        subName: "Consolidated KPIs from the past projects",
        isTemporalData: false,
    }

]

const ViewScreen = () => {
    const [searchInput, setSearchInput] = useState('');
    const [filteredViews, setFilteredViews] = useState<ViewData[]>(ViewsData);
    const [nodeLabelsLoading, setNodeLabelsLoading] = useState<boolean>(false)
    const [graphData, setGraphData] = useState<viewCountData[]>([]);
    const [DatabaseMonth, setDatabaseMonth] = useState<any>([]);
    const [DatabaseYear, setDatabaseYear] = useState<any>([]);
    const [selectedMonth, setSelectedMonth] = useState<any>(['']);
    const [selectedYear, setSelectedYear] = useState<any>(['']);
    const navigate = useNavigate()
    const getStartedModal = useAppSelector((state) => state.getStartedModalReducer.isGetStartedModal)
    const [LatestDataRefresh, setLatestDataRefresh] = useState<any>([])

    const handleSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value
        setSearchInput(value)
        const filtered = ViewsData.filter(view =>
            view.name.toLowerCase().includes(value.toLowerCase()) ||
            view.subName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredViews(filtered);
    }


    useEffect(() => {
        if (!localStorage.getItem('maxNodes')) {
            localStorage.setItem('maxNodes', '1000');
        }
        getDatabaseList();
    }, []);
    useEffect(() => {
        if (selectedMonth.length > 0 && selectedYear.length > 0) {
            getAllViewsCount();
        }
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        async function getLatestDataRefresh() {
            let query = `
            use srmsellside
            MATCH (n:\`Fiscal Month\`)
            RETURN MAX(n.fiscalMonth) AS maxFiscalMonth
            `
            try {
                const response = await runCipherQuery(query)
                const year = response.graphData[0]?.low.toString().slice(0, 4)
                const monthName = [response.graphData[0]?.low.toString().slice(4)]
                const monthName_year =  year + ' ' + monthName      

                setLatestDataRefresh(monthName_year)
            }
            catch (err) {

            }   
        }
        getLatestDataRefresh();
    }, [])


    async function getAllViewsCount() {

        ViewsData.forEach(async (view, index) => {
            let dbName: string
            view.isTemporalData == true ? dbName = view.dbName + selectedMonth + selectedYear : dbName = view.dbName;
            const query =
                `use ${dbName}
            match(n)
            with count(distinct n) as node_count,count(distinct labels(n)) as label_count,collect(distinct(labels(n))) as label
            match(a)-[r]->(b)
            return node_count,label_count,count(distinct r) as rleationship_count,count(distinct type(r)) as relationship_type,label
            `
            getViewCount(query, index);
        });
    }

    async function getViewCount(query: string, index: number) {
        setNodeLabelsLoading(true);
        try {
            const response = await runCipherQuery(query);
            setGraphData(prevGraphData => {
                const updatedGraphData = [...prevGraphData];
                updatedGraphData[index] = {
                    nodeCount: response?.graphData?.[0]?.low,
                    labelCount: response?.graphData?.[1]?.low,
                    relationshipCount: response?.graphData?.[2]?.low,
                    relationshipTypeCount: response?.graphData?.[3]?.low,
                    labels: response.graphData?.[4]?.map(label => label[0])
                };
                return updatedGraphData;
            });
        setNodeLabelsLoading(false);
        }
        catch (err) {
        }
        setNodeLabelsLoading(false);
    }

    async function getDatabaseList() {
        setNodeLabelsLoading(true);

        const allMonthsSet = new Set<string>();
        const allYearsSet = new Set<string>();

        const promises = ViewsData.map(async (view) => {
            const dbName = view.dbName;
            const query = `use ${dbName} CALL dbms.listPools() YIELD databaseName WHERE databaseName STARTS WITH '${dbName}' WITH DISTINCT databaseName RETURN COLLECT(databaseName) AS databaseNames`;

            try {
                const response = await runCipherQuery(query);
                const dbList = response.graphData[0].slice(1).map(db => db);

                dbList.forEach((name) => {
                    const month = NumberToMonthNames[name.slice(-6, -4)];
                    const year = name.slice(-4);

                    allMonthsSet.add(month);
                    allYearsSet.add(year);
                });
            } catch (err) {
                console.error('Error fetching database list:', err);
            }
        });

        await Promise.all(promises);

        const allMonths = Array.from(allMonthsSet);
        const allYears = Array.from(allYearsSet).sort(); // Ensure years are sorted

        setDatabaseMonth(allMonths);
        setDatabaseYear(allYears);

        // On default set the latest month and latest year
        if (allMonths.length > 0 && allYears.length > 0) {
            setSelectedMonth([monthNameToNumber[allMonths.slice(-1)[0]]]);
            setSelectedYear(allYears.slice(-1)[0]);
        } else {
            console.error('No months or years found');
        }

        setNodeLabelsLoading(false);
    }



    const onClickView = (eachViewData: ViewData, index: number) => {

        let combineMonthYear: string
        eachViewData.isTemporalData == true ? combineMonthYear = eachViewData.dbName + selectedMonth + selectedYear : combineMonthYear = eachViewData.dbName
        localStorage.setItem('dbName', combineMonthYear);
        
        navigate(`graph/${index}`)
    }


    const handleChangeMonth = (index: number, event: SelectChangeEvent<any>) => {
        const newMonth = [...selectedMonth]
        newMonth[index] = event.target.value;
        setSelectedMonth(newMonth);

    };
    const handleChangeYear = (index: number, event: SelectChangeEvent<any>) => {
        const newYear = [...selectedYear]
        newYear[index] = event.target.value;
        setSelectedYear(newYear[index]);
    };

    return (
        <>
            <Box data-testid="views-screen" width="100%" display="flex" pt="1.5rem" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap={2}>
                    <Typography sx={{ fontSize: "1.875rem" }}>Views</Typography>
                </Box>
                <Box display="flex">
                    <Input
                        data-testid="views-search-input"
                        value={searchInput}
                        onChange={(e) => handleSearch(e)}
                        type="text"
                        sx={{
                            height: "3.1rem",
                            width: "27rem",
                            borderRadius: "0px",
                            border: "1px solid #E6E6E6",
                            paddingLeft: "1rem",
                            backgroundColor: "white"
                        }}
                        disableUnderline={true}
                        placeholder="Search Views"
                        endAdornment={
                            <Button
                                variant="text"
                                sx={{
                                    border: "0px",
                                    borderRadius: "0px",
                                    backgroundColor: "#461E96",
                                    ":hover": { backgroundColor: "#461E96" },
                                    color: "white",
                                    height: "100%"
                                }}
                            >
                                <SearchIcon />
                            </Button>
                        }
                    />
                </Box>
            </Box>

            <Box mt={5} marginTop={"1rem"} paddingBottom={"1rem"}>
                <Grid container spacing={3} >
                    {filteredViews.map((eachViewData, index: number) => (
                        <Grid className='first-step' key={index} item xs={4} >
                            <Box border=" 2px solid #EDF0F7" p={2} sx={{
                                backgroundColor: "#ffffff", 
                                borderRadius: "0.625rem",
                                boxSizing: 'border-box',
                                height: '100%',
                                ":hover": {
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                                    // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                                },
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                                <Box>
                                    <Box>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography sx={{ color: "black", fontSize: "1rem", fontWeight: 400 }}>{eachViewData.name}</Typography>
                                            <IconButton sx={{ backgroundColor: "#8c7ed71f" }} onClick={() => onClickView(eachViewData, index)}>
                                                <ArrowForwardIosIcon sx={{ color: "#461e96", width: "1.2rem", height: "1.2rem" }}></ArrowForwardIosIcon>
                                            </IconButton>
                                        </Box>
                                        {eachViewData.isTemporalData == true &&

                                            <><Typography sx={{ color: "#636363", fontSize: ".75rem", fontWeight: 400, }}>*The default selection includes the latest month and year.</Typography><Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={"1vh"} marginTop={"1vh"}>
                                                <FormControl required fullWidth sx={{
                                                    marginTop: ".5rem", marginBottom: "1rem",
                                                    '.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                                                        paddingTop: "4px"
                                                    },
                                                    '.css-120r3w-MuiFormLabel-root-MuiInputLabel-root': {
                                                        top: "-2px"
                                                    }
                                                }} size="small">
                                                    <InputLabel id="demo-select-small-label">Month</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={selectedMonth}
                                                        label="Age"
                                                        onChange={(event) => { handleChangeMonth(index, event); }}
                                                    >
                                                        {DatabaseMonth.map((month, index) => (
                                                            <MenuItem key={index} value={monthNameToNumber[month]}>{month}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl required fullWidth sx={{
                                                    marginTop: ".5rem", marginBottom: "1rem",
                                                    '.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                                                        paddingTop: "4px"
                                                    },
                                                    '.css-120r3w-MuiFormLabel-root-MuiInputLabel-root': {
                                                        top: "-2px"
                                                    }
                                                }} size="small">
                                                    <InputLabel id="demo-select-small-label">Year</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={selectedYear}
                                                        label="Age"
                                                        onChange={(event) => { handleChangeYear(index, event); }}
                                                    >
                                                        {DatabaseYear.map((year, index) => (
                                                            <MenuItem key={index} value={year}>{year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box></>
                                        }
                                        <Typography sx={{ color: "#808080", fontSize: "0.75rem", fontWeight: 400 }}>{eachViewData.subName}</Typography>
                                    </Box>

                                    {nodeLabelsLoading ?

                                        <>
                                            <br />
                                            {eachViewData.isTemporalData ?
                                                <>
                                                    <Skeleton width={'70%'} />
                                                    <Skeleton />
                                                    <Skeleton height={70} />
                                                </>
                                                :
                                                <>
                                                    <Skeleton width={'70%'} />
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <Skeleton height={150} />
                                                </>
                                            }
                                        </>
                                        :

                                        <>
                                            <Box mt={2} display="flex" flexDirection="row" flexWrap={"wrap"} gap={"1vh"} marginTop={"10px"}>
                                                <Typography sx={{ color: "#636363", fontSize: "0.75rem", fontWeight: 400 }}>Nodes: {graphData[index]?.nodeCount}</Typography>
                                                <Typography sx={{ color: "#636363", fontSize: "0.75rem", fontWeight: 400 }}>Labels: {graphData[index]?.labelCount}</Typography>
                                                <Typography sx={{ color: "#636363", fontSize: "0.75rem", fontWeight: 400 }}>Relationship: {graphData[index]?.relationshipCount}</Typography>
                                                <Typography sx={{ color: "#636363", fontSize: "0.75rem", fontWeight: 400 }}>Relationship types: {graphData[index]?.relationshipTypeCount}</Typography>
                                            </Box>
                                            <Box borderBottom={"1px solid #D9D9D9"} marginTop={"1vh"} marginBottom={"1.5vh"}></Box>
                                            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start" alignItems="center" overflow={"auto"} maxHeight={"10rem"}>
                                                {graphData[index]?.labels && graphData[index]?.labels.map((node, index) => {
                                                    return (
                                                        <Chip key={index} label={node} size='small' sx={{
                                                            marginRight: "1vh",
                                                            marginBottom: "1vh",
                                                            backgroundColor: "#8c7ed71f",
                                                            borderWidth: "1px",
                                                            borderColor: "#8C7ED7",
                                                            color: "#461E96",
                                                        }} />
                                                    );
                                                })}
                                            </Box>

                                        </>
                                    }
                                </Box>
                                {eachViewData.isTemporalData == false && eachViewData.dbName == 'srmsellside' &&
                                    <Typography style={{ paddingTop: "1rem", fontSize: "0.9rem", color: "#808080" }}>Latest fiscal month: {LatestDataRefresh}</Typography>
                                }
                            </Box>
                        </Grid>
                    ))}
                </Grid> 
                {filteredViews && filteredViews.length == 0 && 
                <Typography sx={{display:"flex", justifyContent:"center", alignItems:"center", height:"60vh"}}>No views found</Typography>
                }  

            </Box>
            <GetStartesModal open={getStartedModal} />
        </>
    );
}


export default ViewScreen